import React, { Component, Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import { Editor } from '@tinymce/tinymce-react';
import Listing from "../../../fragments/Listing";
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import Accordion from "../../../components/accordion/Accordion";
import ListingTab from "./ListingTab";
import ListingPreview from "../ListingPreview";

class Integrations extends ListingTab {

    render() {
        const { form } = this.props;

        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        <Accordion
                            title="Social Media"
                            titleI18nKey="myListings.editView.tabSocial"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderSocialMedia()}
                        </Accordion>

                        <Accordion
                            title="Google Places"
                            titleI18nKey="myListings.editView.tabGooglePlaces"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderGooglePlaces()}
                        </Accordion>

                        <Accordion
                            title="Trip Advisor"
                            titleI18nKey="myListings.editView.tabTripAdvisor"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderTripAdvisor()}
                        </Accordion>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderSocialMedia() {
        const { form } = this.props;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Facebook"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.facebook">Facebook</Trans>}
                        fullWidth
                    />
                    <p className="field-help-text">
                        <Trans i18nKey="myListings.editView.facebookHelp">
                            Copy and paste your Facebook page link with https://. E.G: https://facebook.com
                        </Trans>
                    </p>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Twitter"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.twitter">Twitter</Trans>}
                        fullWidth
                    />
                    <p className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Copy and paste your Twitter link with https://. E.G: https://twitter.com
                        </Trans>
                    </p>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Instagram"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.instagram">Instagram</Trans>}
                        fullWidth
                    />
                    <p className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Copy and paste your Instagram link with https://. E.G: https://instagram.com
                        </Trans>
                    </p>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Youtube"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.youtube">Youtube</Trans>}
                        fullWidth
                    />
                    <p className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Copy and paste your Youtube link with https://. E.G: https://youtube.com
                        </Trans>
                    </p>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="LinkedIn"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.linkedIn">LinkedIn</Trans>}
                        fullWidth
                    />
                    <p className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Copy and paste your LinkedIn link with https://. E.G: https://linkedin.com
                        </Trans>
                    </p>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="SocialHashTag"
                        label={<Trans i18nKey="myListings.editView.socialHashTag">Social Hash Tag</Trans>}
                        variant="outlined"
                        fullWidth
                    />
                    <p className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterkHelp">
                            Enter your social hash tag starting with a #. E.G #discoverLK
                        </Trans>
                    </p>
                </Grid>
            </Grid>
        )
    }

    renderGooglePlaces() {
        const { form } = this.props;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="GooglePlacesLink"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.googlePlacesLink">Google Places Link</Trans>}
                        fullWidth
                    />
                    <p className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Copy and paste your Google places link with https://. E.G: https://google.com
                        </Trans>
                    </p>
                </Grid>
            </Grid>
        )
    }

    renderTripAdvisor() {
        const { form } = this.props;
        const subscriptionPlan = form.getField('ListingSubscription.Plan');

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="TripAdvisorID"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.tripAdvisorID">TripAdvisor ID</Trans>}
                        fullWidth
                        disabled={!subscriptionPlan.TripadvisorEmbed}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default {
    id: 'integrations',
    tabURL: '/listing/edit/:id/integrations',
    label: 'Integrations',
    component: Integrations,
    fragment: Listing,
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

    }
};