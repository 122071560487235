import React, { Component, Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import Accordion from "../../../components/accordion/Accordion";
import UploadField, { getFileName } from '../../../components/form/UploadField';
import Listing from "../../../fragments/Listing";
import ListingTab from "./ListingTab";

class Media extends ListingTab {

    render() {
        return (
            <Fragment>
                <Grid container spacing={4}>
                    <Grid item className="panels">
                        <Accordion
                            title="Images"
                            titleI18nKey="myListings.editView.tabImages"
                            defaultExpanded
                        >
                            {this.renderImages()}
                        </Accordion>

                        <Accordion
                            title="Videos"
                            titleI18nKey="myListings.editView.tabVideos"
                            defaultExpanded
                        >
                            {this.renderVideos()}
                        </Accordion>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderImages() {
        const { form } = this.props;

        const subscriptionPlan = form.getField('ListingSubscription.Plan');

        const logo = form.getField('Logo');
        const mainImage = form.getField('MainImage');
        const galleryImages = form.getField('GalleryImages');

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <h4><Trans i18nKey="myListings.editView.logo">Logo</Trans></h4>
                    <UploadField
                        folderPath={`ListingImages/Portal/${form.getField('ID')}`}
                        name="Logo"
                        form={form}
                    />

                    {!!logo && !!logo.ID && this.renderImage(logo, this.onLogoRemove.bind(this))}
                </Grid>

                <Grid item className="field-holder">
                    <h4><Trans i18nKey="myListings.editView.mainImage">Main Image</Trans></h4>
                    <UploadField
                        folderPath={`ListingImages/Portal/${form.getField('ID')}`}
                        name="MainImage"
                        form={form}
                    />

                    {!!mainImage && !!mainImage.ID && this.renderImage(mainImage, this.onMainImageRemove.bind(this))}
                </Grid>

                <Grid item className="field-holder">
                    <h4><Trans i18nKey="myListings.editView.galleryImages">Gallery Images</Trans></h4>
                    <UploadField
                        folderPath={`ListingImages/Portal/${form.getField('ID')}`}
                        onComplete={({ uploadFile }) => this.handleUploadGalleryImage(uploadFile)}
                        disabled={galleryImages.length >= subscriptionPlan.NumberOfImages}
                    />
                    <div className="field-help-text">
                        { subscriptionPlan.NumberOfImages === 1 ? (
                            <Trans i18nKey="myListings.editView.categoryHelp">
                                1 gallery image is allowed to be uploaded in package
                            </Trans>
                        ) : (
                            <Fragment>
                                <span>{subscriptionPlan.NumberOfImages}</span>&nbsp;
                                <Trans i18nKey="myListings.editView.categoryHelp">
                                    Gallery images are allowed to be uploaded in package
                                </Trans>
                            </Fragment>
                        )}
                        &nbsp;<strong>"{subscriptionPlan.Name}"</strong>
                    </div>

                    {!!galleryImages && !!galleryImages.length && galleryImages.map(
                        (galleryImage, index) => this.renderImage(
                            galleryImage,
                            this.onGalleryImageRemove.bind(this),
                            index
                        )
                    )}
                    {galleryImages.length >= subscriptionPlan.NumberOfImages &&
                        <div className="field-help-text">
                            {galleryImages.length > subscriptionPlan.NumberOfImages &&
                                <Trans i18nKey="myListings.editView.galleryImagesNotice">
                                    {'Please note only ' + Number(subscriptionPlan.NumberOfImages) + ' image(s) will be displayed in front end.'}
                                </Trans>
                            }&nbsp;
                            <Trans i18nKey="myListings.editView.galleryImagesHelp">
                                Please remove and re-upload if you need to replace the above image(s).
                            </Trans>
                        </div>
                    }

                </Grid>

            </Grid>
        );
    }

    renderImage(image, onClickRemove, index) {
        return (
            <div className="uploaded-image-details">
                <div className="uploaded-image-detail">
                    <img src={image.ImageThumbnailURL} width={100} height={100} />
                    <Button title="Remove image" variant="link-red" onClick={(e) => onClickRemove(index)}>
                        Remove
                    </Button>
                </div>
            </div>
        );
    }

    renderVideos() {
        const { form } = this.props;
        const subscriptionPlan = form.getField('ListingSubscription.Plan');

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="VideoLink"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.videoLink">Video Link</Trans>}
                        fullWidth
                        disabled={!subscriptionPlan.VideoEmbed}
                    />
                    {subscriptionPlan.VideoEmbed ? (
                        <div className="field-help-text">
                            <Trans i18nKey="myListings.editView.bookingLinkHelp">
                                Only youtube videos are allowed. eg: https://www.youtube.com/watch?v=xxxxxxxx
                            </Trans>
                        </div>
                    ) : (
                        <div className="field-help-text">
                            <Trans i18nKey="myListings.editView.bookingLinkHelp">
                                Video embed is not supported in package
                            </Trans>
                            &nbsp;<strong>"{subscriptionPlan.Name}"</strong>
                        </div>
                    )}
                </Grid>
            </Grid>
        )
    }

    onLogoRemove() {
        const { form } = this.props;
        form.setField({
            Logo: {
                ID: 0,
                AbsoluteLink: null,
                Name: null,
            },
            LogoID: 0,
        })
    }

    onMainImageRemove() {
        const { form } = this.props;
        form.setField({
            MainImage: {
                ID: 0,
                AbsoluteLink: null,
                Name: null,
            },
            MainImageID: 0,
        })
    }

    handleUploadGalleryImage(uploadFile) {
        const { form } = this.props;
        const GalleryImages = form.getField('GalleryImages');

        GalleryImages.push({
            ID: uploadFile.id,
            AbsoluteLink: uploadFile.url,
            ImageThumbnailURL: uploadFile.url,
            Name: getFileName(uploadFile.filename)
        });

        form.setField({ GalleryImages });
    };

    onGalleryImageRemove(index) {
        const { form } = this.props;
        const GalleryImages = form.getField('GalleryImages');
        delete GalleryImages[index];
        form.setField({ GalleryImages });
    };
}

export default {
    id: 'media',
    tabURL: '/listing/edit/:id/media',
    label: 'Images & Videos',
    component: withTranslation()(Media),
    fragment: Listing,
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

        // handle logo image save
        const logo = saveData.Logo;
        if (logo && logo.ID) {
            saveData.LogoID = logo.ID;
        }
        delete saveData.Logo;

        // handle main image save
        const mainImage = saveData.MainImage;
        if (mainImage && mainImage.ID) {
            saveData.MainImageID = mainImage.ID;
        }
        delete saveData.MainImage;

        // handle gallery images save
        if (saveData && saveData.GalleryImages) {
            const images = [];
            state.GalleryImages.forEach(function(image) {
                images.push(image.ID);
            });
            saveData.GalleryImages = images;
        }
    }
};