import gql from "graphql-tag";
import ListingSubscriptionPlan from "./ListingSubscriptionPlan";

export const ListingFields = `
	ID
	ClassNameShort
	URLSegment
	Status
	Title
	StreetAddress
	City
	Suburb
	State
	PostCode
	Country
	Phone
	Email
	Website
	Summary
	Content
	Facilities
	BestTimeToTravel
	AgeRestrictions
	Featured
	MobilePhone
	BookingLink
	VideoLink
	Location
	OpeningHours
	IsTwentyFourSeven
	Facebook
	Twitter
	Instagram
	Youtube
	LinkedIn
	SocialHashTag
	AverageSpend
	MinPrice
	MaxPrice
	ContactName
	ContactEmail
	ContactPhone
	ProximityToTown
	ProximityToAirport
	ProximityToRailway
	ProximityToBus
	SpecialAlert
	Deal
	Version
	VersionForReview
	Logo {
		... on SilverStripeImage {
			ID
			Name
			Size
			AbsoluteLink
			ImageThumbnailURL
		}
	}
	MainImage {
		... on SilverStripeImage {
			ID
			Name
			Size
			AbsoluteLink
			ImageThumbnailURL
		}
	}
	MainCategory {
		ID
		Title
	}
	ListingSubscription {
		ID
		Plan { 
			...ListingSubscriptionPlan
		}
	}
	Categories {
		ID
		Title
	}
	Tags {
		ID
		Title
	}
	GalleryImages {
		... on SilverStripeImage {
			ID
			Name
			Size
			AbsoluteLink
			ImageThumbnailURL
		}
	}
`;

export default gql`
	fragment ListingFragmentListing on Listing {
		${ListingFields}
	}
	${ListingSubscriptionPlan}
`;
