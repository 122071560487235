import { createMuiTheme, responsiveFontSizes, makeStyles } from '@material-ui/core/styles';

const font = "'Circular', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', 'sans-serif'";


let theme = createMuiTheme({
    palette: {
        background: {
            paper: '#FFFFFF',
            default: 'transparent'
        },
        text: {
            primary: '#484848'
        }
    },
    typography: {
        fontFamily: font,
        fontSize: 14,
        htmlFontSize: 20,
        h1: {
            fontFamily: font,
            fontSize: 32,
            lineHeight: 1.18,
            fontWeight: 500
        },
        h2: {
            fontFamily: font,
            fontSize: 32,
            lineHeight: 1.18,
            fontWeight: 500
        },
        h3: {
            fontFamily: font,
            fontSize: 22,
            lineHeight: 1.18,
            fontWeight: 500
        },
        h4: {
            fontFamily: font,
            fontSize: 16,
            lineHeight: 1.25,
            fontWeight: 500
        },
        h5: {
            fontFamily: font,
            fontSize: 16,
            lineHeight: 1.25,
            fontWeight: 600
        },
        h6: {
            fontFamily: font,
            fontSize: 14,
            lineHeight: 1.25,
            fontWeight: 600
        },
        body1: {
            fontFamily: font,
            fontSize: 14,
            htmlFontSize: 16,
            lineHeight: 1.24
        },
        body2: {
            fontFamily: font,
            fontSize: 14,
            htmlFontSize: 16,
            lineHeight: 1.24
        }
    }
});


theme = responsiveFontSizes(theme);
export default theme;


