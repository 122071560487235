import React, { Component, Fragment } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Button from '@material-ui/core/Button';
import Listing from "../../../fragments/Listing";
import Grid from "../../../components/layout/Grid";
import ListingTab from "./ListingTab";
import ListingPreview from "../ListingPreview";
import Spinner from "../../../components/loading/Spinner";

class Preview extends ListingTab {

    render() {
        const { form } = this.props;

        return (
            <Fragment>
                <Grid item className="preview-buttons">
                    {this.showSubmitForReview() && (
                        <Button
                            variant="contained"
                            onClick={e => this.onClickSubmitForReview(e)}
                            disabled={this.state.loadingReviewButton}
                        >
                            {this.state.loadingReviewButton && <Spinner size="xs"/>}
                            Submit for Review
                        </Button>
                    )}
                </Grid>

                <Grid container spacing={0} className='form-panels-container'>
                    <ListingPreview form={form} />
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    showSaveButton() {
        return false;
    }

    showSubmitForReview() {
        return this.canShowSubmitForReview();
    }

    // showSubmitForReview() {
    //     const { form } = this.props;
    //
    //     return form.getField('Status') === 'Draft' || form.getField('Status') === 'Approved';
    // }

}

export default {
    id: 'preview',
    tabURL: '/listing/edit/:id/preview',
    label: 'Preview',
    component: Preview,
    fragment: Listing,
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

    }
};