import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';
import { joinDefined } from '../../utils/strings';
import {getUser} from "../../utils/sessions";

const CremationOrderAutoComplete = ({ category, className, name, clearOnSelect = true, ...props }) => {
    return (
        <GqlAutocomplete
            className={className}
            readAllQuery={readListings}
            placeholder="Listing..."
            labelFieldFunc={e => e.Title}
            multiple={true}
            name={name}
            clearOnSelect={clearOnSelect}
            extraVariables={{ memberID: 0 }}
            {...props}
        />
    );
};

const readListings = gql`
    query ReadListings($contains: String, $memberID: ID) {
        readListings(contains: $contains, memberID: $memberID limit: 5, offset: 0) {
            edges {
                node {
                    ID
                    Title
                }
            }
        }
    }
`;

export default CremationOrderAutoComplete;
