import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from 'react-router-dom';
import Route from '../components/Route';
import NotFound from './NotFound';
import Dashboard from './Dashboard/Dashboard';
import Login from "./SignUp/Login";
import Register from "./SignUp/Register";
import ResetPassword from "./SignUp/ResetPassword";
import Listings from "./Listings/Listings";
import Website from "./Website/Website";
import Account from "./Account/Account";
import BookingSystem from "./BookingSystem/BookingSystem";
import ListingEdit from "./Listings/ListingEdit";

const Main = ({ signedIn }) => (
    <main className={signedIn ? 'app-container' : ''}>
        <Switch>
            <Route auth path="/" exact component={Dashboard} />
            <Route auth path="/listings" exact component={Listings} />
            <Route auth path="/listings/:type" exact component={Listings} />

            <Route auth path="/listing/edit/:id/" exact component={ListingEdit} />
            <Route auth path="/listing/edit/:id/:tab" exact component={ListingEdit} />

            <Route auth path="/manage-website" exact component={Website} />
            <Route auth path="/booking-system" exact component={BookingSystem} />
            <Route auth path="/account" exact component={Account} />

            <Route anon path="/login" component={Login} />
            <Route anon path="/register/" exact component={Register} />
            <Route anon path="/register/:type" exact component={Register} />
            <Route anon path="/reset-password" component={ResetPassword} />

            <Route component={NotFound} />
        </Switch>
    </main>
);

export default withStyles({})(Main);
