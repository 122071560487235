import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Grid from '../layout/Grid';
import { withTranslation, useTranslation, Trans } from 'react-i18next';


export default withTranslation()(({signedIn}) => {

    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const getLanguage = () => {
        return i18n.language;
    };

    let cssClass = 'footer';
    if (signedIn) {
        cssClass += ' footer--black';
    }

    return (
        <div className={cssClass}>
            <div className="app-container">
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <div className="footer-group">
                            <h3>Discover.lk</h3>
                            <p className='footer-group__lang-buttons'>
                                <Button onClick={() => changeLanguage('si')} className={{'active': getLanguage() === 'si'}}>සිං</Button>
                                <Button onClick={() => changeLanguage('en')} className={{'active': getLanguage() === 'en'}}>En</Button>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <div className="footer-group">
                            <h4>
                                <Trans i18nKey="footerMenu.group1.name">
                                    Help
                                </Trans>
                            </h4>
                            <ul>
                                <li><a href="http://discover.lk/terms-and-conditions" rel="noopener noreferrer" target="_blank">
                                    <Trans i18nKey="footerMenu.group1.item1">
                                        Terms & Conditions
                                    </Trans>
                                </a></li>
                                <li><a href="http://discover.lk" rel="noopener noreferrer" target="_blank">
                                    <Trans i18nKey="footerMenu.group1.item2">
                                        Help & Resources
                                    </Trans>
                                </a></li>
                                <li><a href="http://discover.lk/privacy-policy" rel="noopener noreferrer" target="_blank">
                                    <Trans i18nKey="footerMenu.group1.item3">
                                        Privacy Policy
                                    </Trans>
                                </a></li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <div className="footer-group">
                            <h4>
                                <Trans i18nKey="footerMenu.group2.name">
                                    Support Contact
                                </Trans>
                            </h4>
                            <ul>
                                <li>
                                    <strong>
                                        <Trans i18nKey="footerMenu.group2.item1">
                                            Email:
                                        </Trans>
                                    </strong>
                                    <a href="mailto:support@discover.lk" rel="noopener noreferrer" target="_blank">hello@discover.lk</a>
                                </li>
                                <li>
                                    <strong>
                                        <Trans i18nKey="footerMenu.group.item2">
                                            Phone:
                                        </Trans>
                                    </strong>
                                    <a href="tel:94112722566" rel="noopener noreferrer" target="_blank">011 272 2908</a>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <div className="footer-group">
                            <h4>
                                <Trans i18nKey="footerMenu.group3.name">
                                    Our other sites
                                </Trans>
                            </h4>
                            <ul>
                                <li><a href="http://www.discover.lk" rel="noopener noreferrer" target="_blank">www.discover.lk</a></li>
                                <li><a href="http://www.discover.lk" rel="noopener noreferrer" target="_blank">
                                    <Trans i18nKey="footerMenu.group3.item1">
                                        Learning Portal
                                    </Trans>
                                </a></li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )}
);
