import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import gql from 'graphql-tag';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { maxUploadSize } from '../../environment';
import { getAssetsClient } from '../../utils/apollo';
import AttachFileIcon from '../icon/AttachFileIcon';
import Grid from '../layout/Grid';
import Inline from '../layout/Inline';
import Button from './Button';

class UploadField extends Component {

    render() {
        const content = (mutation, { loading }) => this.renderButton(mutation, loading);

        return (
            <Mutation
                mutation={uploadFilesQuery}
                fetchPolicy="no-cache"
                onCompleted={this.onCompleted}
                client={getAssetsClient()}
            >
                {content}
            </Mutation>
        );
    }

    renderButton(mutate, loading) {
        const { classes, disabled, accept } = this.props;

        const randomID = Math.floor(Math.random() * 10000 + 1);
        const isDisabled = !!loading || !!disabled;

        return (
            <div className="upload-field">
                <Grid container spacing={2}>
                    <Grid item>
                        <Inline center>
                            <Button variant="upload" disabled={isDisabled}>
                                {loading ? (
                                    <div
                                        style={{
                                            marginRight: '0.35rem'
                                        }}
                                    >
                                        <CircularProgress size={24} className="icon" />
                                    </div>
                                ) : (
                                    <AttachFileIcon className="icon" />
                                )}
                                Attach from files
                            </Button>
                            <p className="label">Select from a folder or drag and drop</p>
                        </Inline>
                    </Grid>
                </Grid>

                <input
                    type="file"
                    accept={accept}
                    required
                    className={classes.input}
                    id={'raised-button-file' + randomID}
                    onChange={this.onChange(mutate)}
                    disabled={isDisabled}
                />
            </div>
        );
    }

    onChange = mutate => ({
        target: {
            validity,
            files: [file]
        }
    }) => {
        const { parentId, folderPath, publish } = this.props;

        if (typeof file !== 'undefined') {
            const maxSize = maxUploadSize,
                size = file.size;
            if (maxSize < size)
                return window.alert('Sorry, file is too big!\nLimit is ' + maxSize / 1024 / 1024 + ' MB');
        }

        if (!folderPath) {
            return window.alert('CMS folderPath was not provided, upload cannot start.');
        }

        return (
            validity.valid &&
            mutate({
                variables: {
                    file: file,
                    parentId: parentId,
                    publish: true,
                    folderPath: folderPath
                }
            })
        );
    };

    onCompleted = data => {
        const { name, form, onComplete } = this.props;
        if (onComplete) {
            onComplete(data);
        }

        if (name && form) {
            const { id, url, filename } = (data && data.uploadFile) || {};
            form.setState({
                [`${name}.ID`]: id,
                [`${name}.AbsoluteLink`]: url,
                [`${name}.ImageThumbnailURL`]: url,
                [`${name}.Name`]: filename.replace(/(.*?\/)+/, '')
            });
        }
    };

    handleDeleteFile = () => {
        const { name, form, onDelete } = this.props;

        if (!name || !form) return;

        if (onDelete) {
            onDelete(form.getState(name));
        } else {
            form.setState({
                [`${name}.ID`]: 0,
                [`${name}.AbsoluteLink`]: null,
                [`${name}.Name`]: null
            });
        }
    };
}

const uploadFilesQuery = gql`
    mutation($file: Upload!, $parentId: Int, $publish: Boolean, $folderPath: String) {
        uploadFile(file: $file, parentId: $parentId, publish: $publish, folderPath: $folderPath) {
            id
            filename
            title
            parentId
            url
        }
    }
`;

export default withStyles({})(UploadField);

export const getFileName = fileUploadName => {
    const last = fileUploadName.lastIndexOf('/');
    if (last === -1) return fileUploadName;

    return fileUploadName.substring(last + 1);
};
