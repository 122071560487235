import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next'
import Accordion from "../../../components/accordion/Accordion";
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import Listing from "../../../fragments/Listing";
import ListingTab from "./ListingTab";

class DealsAndAlerts extends ListingTab {

    render() {
        const { form } = this.props;

        const specialAlert = <Trans i18nKey="myListings.editView.specialAlert">Special Alert</Trans>;
        const deal = <Trans i18nKey="myListings.editView.deal">Deal</Trans>;

        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">

                        <Accordion
                            title="Special Alert"
                            titleI18nKey="myListings.editView.tabSpecialAlert"
                            className='form-panel'
                            defaultExpanded
                        >
                            <Grid container spacing={0}>
                                <Grid item className="field-holder">
                                    <TextField
                                        form={form}
                                        name="SpecialAlert"
                                        variant="outlined"
                                        label={specialAlert}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                        </Accordion>

                        <Accordion
                            title="Deal"
                            titleI18nKey="myListings.editView.tabDeal"
                            className='form-panel'
                            defaultExpanded
                        >
                            <Grid container spacing={0}>
                                <Grid item className="field-holder">
                                    <TextField
                                        form={form}
                                        name="Deal"
                                        variant="outlined"
                                        label={deal}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

}

export default {
    id: 'dealsAndAlerts',
    tabURL: '/listing/edit/:id/deals-and-alerts',
    label: 'Deals & Alerts',
    component: DealsAndAlerts,
    fragment: Listing,
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {}
};