let _serverUrl = 'http://api.discover.lk';
_serverUrl = 'http://api.discover.uat.lk';
_serverUrl = 'http://dev.api.discover.lk';

let _introspectionQueryResultData = require('./fragments/introspection/fragmentTypesDev');

let _listingPreviewBase = 'https://discover.lk/explore/preview';
_listingPreviewBase = 'http://discover.uat.lk/explore/preview';
_listingPreviewBase = 'http://dev.discover.lk/explore/preview';

if (window.location.hostname === 'portal.discover.uat.lk') {
    _serverUrl = 'http://api.discover.uat.lk';
    _listingPreviewBase = 'http://discover.uat.lk/explore/preview';
}  else if (window.location.hostname === 'portal.discover.lk') {
    _serverUrl = 'https://api.discover.lk';
    _listingPreviewBase = 'https://discover.lk/explore/preview'
}

export const introspectionQueryResultData = _introspectionQueryResultData;
export const serverUrl = _serverUrl;
export const listingPreviewBase = _listingPreviewBase;

export const maxUploadSize = 10485760; // 10MB

export const unsplashAccessKey = '13b896ba78fd3d36cfdcef723f7dd94d30cfed838b62fdf745575f0c5cb781e3';

export const tinyMceAPIKey = 'tsnfwf3i4ybd44ombo6nlpz8p6w13mjs7dy5ymopa5vl47ce';