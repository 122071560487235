import React, { Component, Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import FormLabel from '@material-ui/core/FormLabel';
import { Editor } from '@tinymce/tinymce-react';
import Listing from "../../../fragments/Listing";
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import CategoryTreeField from "../../../components/form/CategoryTreeField";
import Accordion from "../../../components/accordion/Accordion";
import {tinyMceAPIKey} from "../../../environment";
import ListingTab from "./ListingTab";
import {getValidationDecorations, hasErrorsInFields} from "../../../utils/validation";

class BasicDetails extends ListingTab {

    render() {
        const { form } = this.props;

        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        <Accordion
                            title="Basic"
                            titleI18nKey="myListings.editView.tabBasic"
                            className={'form-panel ' + (hasErrorsInFields(['Title'], form) ? 'form-panel__error' : '')}
                            defaultExpanded
                        >
                            {this.renderBasic()}
                        </Accordion>

                        <Accordion
                            title="Content"
                            titleI18nKey="myListings.editView.tabContent"
                            className={'form-panel ' + (hasErrorsInFields(['Summary'], form) ? 'form-panel__error' : '')}
                            defaultExpanded
                        >
                            {this.renderContent()}
                        </Accordion>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderBasic() {
        const { form } = this.props;

        const subscriptionPlan = form.getField('ListingSubscription.Plan');
        const objectType = form.getField('ClassNameShort');

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Title"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.title">Title</Trans>}
                        fullWidth
                    />
                    <div className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Name of your organisation
                        </Trans>
                    </div>
                </Grid>
                <Grid item className="field-holder">
                    <FormLabel>
                        <Trans i18nKey="myListings.editView.categories">
                            Categories
                        </Trans>
                    </FormLabel>
                    <CategoryTreeField
                        threshold={subscriptionPlan.NumberOfCategories}
                        onSelected={e => this.onCategoriesSelected(e)}
                        checkedKeys={form.getField('Categories').map(cat => cat.ID)}
                    />
                    <div className="field-help-text">
                        { subscriptionPlan.NumberOfCategories === 1 ? (
                            <Trans i18nKey="myListings.editView.categoryHelp">
                                1 Category is allowed to be selected in package
                            </Trans>
                        ) : (
                            <Fragment>
                                <span>{subscriptionPlan.NumberOfCategories}</span>&nbsp;
                                <Trans i18nKey="myListings.editView.categoryHelp">
                                    Categories are allowed to be selected in package
                                </Trans>
                            </Fragment>
                        )}
                        &nbsp;<strong>"{subscriptionPlan.Name}"</strong>
                    </div>

                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Phone"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.phone">Phone</Trans>}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="MobilePhone"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.mobilePhone">Mobile Phone</Trans>}
                        fullWidth
                        disabled={!subscriptionPlan.MobilePhone}
                    />
                    {!subscriptionPlan.BookingLink && (
                        <div className="field-help-text">
                            <Trans i18nKey="myListings.editView.bookingLinkHelp">
                                Mobile phone is not allowed to be added in package
                            </Trans>
                            &nbsp;<strong>"{subscriptionPlan.Name}"</strong>
                        </div>
                    )}
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Website"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.website">Website</Trans>}
                        fullWidth
                    />
                    <div className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Enter with https://www.
                        </Trans>
                    </div>
                </Grid>
                {(objectType === 'Activity' || objectType === 'Accommodation') && (
                    <Grid item className="field-holder">
                        <TextField
                            form={form}
                            name="BookingLink"
                            variant="outlined"
                            label={<Trans i18nKey="myListings.editView.bookingLink">Booking Link</Trans>}
                            fullWidth
                            disabled={!subscriptionPlan.BookingLink}
                        />
                        {subscriptionPlan.BookingLink ? (
                            <div className="field-help-text">
                                <Trans i18nKey="myListings.editView.twitterHelp">
                                    Enter with https://www.
                                </Trans>
                            </div>
                        ) : (
                            <div className="field-help-text">
                                <Trans i18nKey="myListings.editView.bookingLinkHelp">
                                Booking Link is not allowed to be added in package
                                </Trans>
                                &nbsp;<strong>"{subscriptionPlan.Name}"</strong>
                            </div>
                        )}
                    </Grid>
                )}
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Email"
                        label={<Trans i18nKey="myListings.editView.email">Email</Trans>}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
        )
    }

    renderContent() {
        const { form } = this.props;
        const objectType = form.getField('ClassNameShort');

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        onError={() => this.setState({ errorInContent: true })}
                        form={form}
                        name="Summary"
                        label={<Trans i18nKey="myListings.editView.summary">Summary</Trans>}
                        multiline
                        rows={5}
                        variant="outlined"
                        fullWidth
                    />
                    <div className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Enter about 100 letters
                        </Trans>
                    </div>
                </Grid>
                <Grid item className="field-holder">
                    <FormLabel>
                        <Trans i18nKey="myListings.editView.content">
                            Content
                        </Trans>
                    </FormLabel>
                    <Editor
                        apiKey={tinyMceAPIKey}
                        plugins="wordcount"
                        onEditorChange={content => this.onContentChange(content)}
                        value={form.getField('Content')}
                        init={{
                            height: 400,
                            plugins: "paste",
                            paste_as_text: true,
                            paste_remove_styles_if_webkit: true,
                            paste_retain_style_properties: "all",
                        }}
                    />
                    <div className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Add as much as details you can about your organisation.
                        </Trans>
                    </div>
                </Grid>
                {(objectType === 'Activity' || objectType === 'Accommodation') && (
                    <Grid item className="field-holder">
                        <TextField
                            form={form}
                            name="BestTimeToTravel"
                            variant="outlined"
                            label={<Trans i18nKey="myListings.editView.bestTimeToTravel">Best Time To Travel</Trans>}
                            fullWidth
                        />
                        <div className="field-help-text">
                            <Trans i18nKey="myListings.editView.twitterHelp">
                                E.G: Mar - Oct
                            </Trans>
                        </div>
                    </Grid>
                )}
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="AgeRestrictions"
                        variant="outlined"
                        label={<Trans i18nKey="myListings.editView.ageRestrictions">Age Restrictions</Trans>}
                        fullWidth
                    />
                </Grid>
            </Grid>
        )
    }

    onMainCategorySelected(checked) {
        const { form } = this.props;

        if (checked && checked.length) {
            form.setField({ MainCategoryID: Number(checked[0]) });
        }
    }

    onCategoriesSelected(checked) {
        const { form } = this.props;

        let Categories = [];
        if (checked && checked.length) {
            checked.map(catID => Categories.push({ ID: catID}));
        }
        form.setField({ Categories });
    }

    onContentChange(Content) {
        const { form } = this.props;
        form.setField({ Content });
    }

}

export default {
    id: 'business',
    tabURL: '/listing/edit/:id/basic',
    label: 'Your Business',
    component: withTranslation()(BasicDetails),
    fragment: Listing,
    validation: {
        required: ['Title', 'Summary']
    },
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {
        if (saveData && saveData.Categories) {
            const categories = [];
            state.Categories.forEach(function(category) {
                categories.push(category.ID);
            });
            saveData.Categories = categories;
        }
    }
};