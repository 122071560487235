import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { setToken } from '../../utils/sessions';
import { joinDefined } from '../../utils/strings';
import { getAuthClient } from '../../utils/apollo';

class Login extends Component {
    state = {
        email: '',
        password: '',
        remember: false
    };

    render() {
        return (
            <Card className='welcome-card'>
                <Mutation client={getAuthClient()} mutation={loginMutation} onCompleted={this.onCompleted}>
                    {this.renderForm}
                </Mutation>
            </Card>
        );
    }

    renderForm = (mutate, results = {}) => {
        const { data, loading } = results;
        const { email, password, remember } = this.state;
        const onSubmit = e => {
            if (e) e.preventDefault();
            if (loading) return;
            mutate({
                variables: { email, password }
            });
        };
        let error;
        // TODO proper error messaging for network errors
        if (data && data.createToken.Token === null) {
            error = 'Incorrect email or password.';
        }

        return (
            <ValidatorForm
                className="sign-in login-popup"
                onSubmit={onSubmit}
                ref="form"
            >
                <Typography variant="h3" gutterBottom>
                    Sign In
                </Typography>
                <Typography component="p" variant="body1">
                    If you have an account associated with the portal please sign in below.
                </Typography>
                {error && (
                    <Typography component="p" variant="body1" className="form-message form-message--bad">
                        {error}
                    </Typography>
                )}
                <div className='field-holder'>
                    <TextValidator
                        fullWidth
                        onChange={this.onChangeText('email')}
                        label="Email"
                        value={email}
                        inputMode="email"
                        validators={['required']}
                        errorMessages={['this field is required']}
                        variant="outlined"
                    />
                </div>
                <div className='field-holder'>
                    <TextValidator
                        fullWidth
                        onChange={this.onChangeText('password')}
                        label="Password"
                        value={password}
                        type="password"
                        validators={['required']}
                        errorMessages={['this field is required']}
                        variant="outlined"
                    />
                </div>
                <div className='field-holder'>
                    <FormControlLabel
                        control={<Checkbox checked={remember} onChange={this.onToggleRemember} color="primary" />}
                        label="Keep me signed in"
                    />
                </div>
                <div className='action-buttons'>
                    <div className='button-flex'>
                        <Button type="submit" variant="contained" className="button-base primary">
                            Sign In
                        </Button>
                        <Typography component="span" variant="body1">
                            <a href="/register">No account yet?</a>
                        </Typography>
                    </div>
                    <Typography component="p" variant="body1">
                        <a href="/reset-password">I've lost my password</a>
                    </Typography>
                </div>
            </ValidatorForm>
        );
    };

    onChangeText = name => e => {
        this.setState({ [name]: e.target.value });
    };

    onToggleRemember = e => {
        this.setState({ remember: e.target.checked });
    };

    onCompleted = data => {
        const { Token, Member } = (data && data.createToken) || {};
        if (!Token) return;
        const { ID, FirstName, Surname } = Member;
        setToken({
            userid: ID,
            value: Token,
            name: joinDefined([FirstName, Surname], ' ')
        });
        const { location, history } = this.props;
        const redirectPath = (location.state && location.state.referrer) || '/';
        history.push(redirectPath);
    };
}

export const loginMutation = gql`
    mutation Login($email: String!, $password: String!) {
        createToken(Email: $email, Password: $password) {
            Token
            Member {
                ID
                FirstName
                Surname
            }
        }
    }
`;

export default withStyles({})(Login);
