import gql from 'graphql-tag';
import {capitalize} from '../../utils/strings';

export const createTableQuery = (
	queryName,
	fragment,
	dataObject,
	filterByFragment,
	isSortable,
    memberID,
	versioningMode
) => {
	const capitalizedQueryName = capitalize(queryName);
	const objectName = dataObject || queryName.replace(/^read(.*)s/, '$1');

	const sortByInput = isSortable
		? `$sortBy: [${capitalizedQueryName}SortInputType]`
		: '';
	const sortByInputVar = isSortable ? `sortBy: $sortBy` : '';

    const memberIDInput = memberID ? '$memberID: ID' : '';
    const memberIDInputVar = memberID ? `memberID: $memberID` : '';

    const versionString = versioningMode ? `Versioning: { Mode: ${versioningMode} }` : '';

	const filterByInfo = filterByFragment || '';

	return gql`
		${fragment}
		query ${capitalizedQueryName}(
            $limit: Int
            $offset: Int
            $contains: String
			${sortByInput}
			${memberIDInput}
		){
			${queryName}(
			    limit: $limit
			    offset: $offset 
			    contains: $contains
				${sortByInputVar}
				${memberIDInputVar}
				${versionString}
			){
				edges {
					node {
						ID
						...${fragment.definitions[0].name.value}
					}
				}
				pageInfo {
                    hasNextPage
                    hasPreviousPage
                    totalCount
                }
                ${filterByInfo}
			}
			${getTypeQuery(`${capitalizedQueryName}SortInputType`)}
		}`;
};

export const getTypeQuery = typeName => {
	return `
		__type(name:"${typeName}"){
			name
			kind
			inputFields {
				name
				type {
					name
					kind
					ofType {
						kind
						name
						description
						enumValues {
							name
							description
						}
					}
				}
			}
		}`;
};
