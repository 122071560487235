import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from "graphql-tag";
import { Icon, Button, Typography } from '@material-ui/core';
import { withTranslation, Trans } from 'react-i18next'
import Grid from '../../components/layout/Grid';
import PricingPackages from "./PricingPackages";
import DataTable from '../../components/dataTable/DataTable'
import {isFunction, joinDefined} from "../../utils/strings";
import {getUser} from "../../utils/sessions";
import ViewHeader from '../../components/header/ViewHeader';
import Spinner from '../../components/loading/Spinner';
import Modal from '../../components/modal/Modal';
import Table, { Row, Cell } from '../../components/table/Table';
import CreateListingModal from './Modals/CreateListingModal';
import ClaimListingModal from "./Modals/ClaimListingModal";

class Listings extends Component {

    state = {
        showPricingModal: false,
        showCreateModal: false,
        showClaimingModal: false,
        selectedPlan: 0,
        hasClaimedListings: false,
    };

    refetchClaims = null;

    static getDerivedStateFromProps({ match }, oldState) {
        if (match.params.type && match.params.type === 'new') {
            // todo - this should be uncommented when pricing popup is okay to appear
            // return {
            //     showPricingModal: true,
            // }

            // todo - remove this when pricing popup is okay to appear
            return {
                selectedPlan: 1,
                showCreateModal: true,
            }
        }
        if (match.params.type && match.params.type === 'claim') {
            return {
                showClaimingModal: true
            }
        }
        return null;
    }

    render() {
        const { showPricingModal, showClaimingModal, showCreateModal, selectedPlan } = this.state;
        const { history } = this.props;

        return (
            <Fragment>

                {this.renderListings()}

                {this.renderClaimedRequests()}

                <Modal
                    onClose={() => this.onClosePricingModal()}
                    open={showPricingModal}
                    canClickOut={false}
                    title="Select a Pricing Plan"
                >
                    <PricingPackages onSelectPackage={planID => this.onSelectPackage(planID)} />
                </Modal>

                <CreateListingModal
                    onClose={() => this.onCloseCreateModal()}
                    open={showCreateModal}
                    planID={selectedPlan}
                    history={history}
                >
                    <PricingPackages />
                </CreateListingModal>

                <ClaimListingModal
                    onClose={() => this.onCloseClaimingModal()}
                    open={showClaimingModal}
                    onClaimed={e => this.fetchClaimedRequests()}
                />

            </Fragment>
        );
    }

    renderListings() {
        const { history } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item>
                    <Typography variant="h1">
                        <Trans i18nKey="myListings.listView.title">
                            List your business on Discover.lk
                        </Trans>
                    </Typography>
                    <Typography variant="body1">
                        <Trans i18nKey="myListings.listView.content">
                            Get your businesses listed on Discover.lk to be found online and generate more bookings. Learn more about
                        </Trans>&nbsp;
                        <a href="http://www.discover.lk/portal" target="_blank">Discover.lk</a>
                    </Typography>
                </Grid>

                <Query
                    fetchPolicy="no-cache"
                    query={hasListingsClaimsQuery}
                    variables={{ memberID: getUser().ID}}
                >
                    {results => {
                        const { loading, data } = results;
                        if (loading) return <Spinner />;

                        if (data && data.readMemberHasListings && !data.readMemberHasListings.HasListings) {
                            return (
                                <Grid container spacing={0}>
                                    <Grid item>
                                        <Typography variant="h2">
                                            <Trans i18nKey="myListings.listView.addTitle">
                                                Add a new listing
                                            </Trans>
                                        </Typography>
                                        <Typography variant="body1">
                                            <Trans i18nKey="myListings.listView.addContent">
                                                Start by choosing a plan. Feel free to start with a free plan if you're not sure, and list
                                                your site on Discover.lk. The best way to help profile your business to a global audience online!
                                            </Trans>
                                        </Typography>

                                        <PricingPackages onSelectPackage={planID => this.setState({
                                            selectedPlan: planID,
                                            showCreateModal: true,
                                        })} />
                                    </Grid>
                                </Grid>
                            )
                        } else {
                            return (
                                <Grid item>
                                    <ViewHeader
                                        variant="record"
                                        titleTag="h2"
                                        title={<Trans i18nKey="myListings.listView.listingsTitle">Your Current Listings</Trans>}
                                    >
                                        <Button variant="outlined" onClick={e => history.push('/listings/new')}>
                                            <Trans i18nKey="myListings.listView.addTitle">
                                                Add a new listing
                                            </Trans>
                                        </Button>
                                    </ViewHeader>

                                    <Grid contianer className='table-container'>
                                        <Grid item className="listings">
                                            <DataTable
                                                queryName="readListings"
                                                fragment={listingFragment}
                                                columns={listingColumns}
                                                variables={{ limit: 10, memberID: getUser().ID }}
                                                brandProperty="ID"
                                                searchVariable="contains"
                                                tableTitle="Listings"
                                                versioningMode="DRAFT"
                                                noResultsMessage={<Trans i18nKey="myListings.noResultsMessage">You have no listings added yet.</Trans>}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        }
                    }}
                </Query>
            </Grid>
        );
    }

    renderClaimedRequests() {
        return (
            <Grid container spacing={3}>
                <Grid item>
                    <Typography variant="h2">
                        <Trans i18nKey="myListings.claimListing.addTitle">
                            Claim an existing listing
                        </Trans>
                    </Typography>
                    <Typography variant="body1">
                        <Trans i18nKey="myListings.claimListing.addContent">
                            If your business is already listed on <strong><a href="https://www.discover.lk" target="_blank">Discover.lk</a></strong>, you can claim the listing by clicking the button below.
                        </Trans>
                    </Typography>
                </Grid>

                <Query query={claimedRequestsQuery} variables={{ memberID: getUser().ID}}>
                    {results => {
                        const { loading, data, refetch } = results;

                        if (loading) return <Spinner />;

                        this.refetchClaims = refetch;

                        const requests = data.readClaimListingRequests;

                        return (
                            <Grid item>
                                {!!requests.length ? (
                                    <Fragment>
                                        <ViewHeader
                                            variant="record"
                                            titleTag="h3"
                                            title={<Trans i18nKey="myListings.claimListing.title">Your Claimed Listings</Trans>}
                                        >
                                            <Button
                                                variant="outlined"
                                                onClick={e => this.setState( { showClaimingModal: true })}
                                                style={{ marginRight: 10 }}
                                            >
                                                <Trans i18nKey="myListings.claimListing.claimAnother">
                                                    Claim another listing
                                                </Trans>
                                            </Button>
                                        </ViewHeader>
                                        <Grid contianer className='table-container'>
                                            <Grid item className="listings">
                                                <Grid item className='table-holder'>
                                                    <Table
                                                        columns={[{ label: '' }, { label: 'Title' }, { label: 'Category' }, { label: 'Status' }]}
                                                    >
                                                        {requests.map((claim, rowIndex) => {
                                                            const imageSrc = !!claim.Listing.Logo.ImageThumbnailURL
                                                                ? claim.Listing.Logo.ImageThumbnailURL
                                                                : claim.Listing.MainImage.ImageThumbnailURL;
                                                            return (
                                                                <Row pad key={'claim-' + claim.ID}>
                                                                    <Cell dataLabel="Logo">
                                                                        <img src={imageSrc} />
                                                                    </Cell>
                                                                    <Cell dataLabel="Title">{claim.Listing.Title}</Cell>
                                                                    <Cell dataLabel="Category">{claim.Listing.MainCategory.Title}</Cell>
                                                                    <Cell dataLabel="Status">{claim.Status}</Cell>
                                                                </Row>
                                                            );
                                                        })}
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <Button
                                            variant="outlined"
                                            onClick={e => this.setState( { showClaimingModal: true })}
                                            style={{ marginRight: 10 }}
                                        >
                                            <Trans i18nKey="myListings.claimListing.claim">
                                                Claim a listing
                                            </Trans>
                                        </Button>
                                    </Fragment>
                                )}
                            </Grid>
                        );

                    }}
                </Query>
            </Grid>
        );
    }

    fetchClaimedRequests() {
        if (this.refetchClaims && isFunction(this.refetchClaims)) {
            this.refetchClaims();
        }
    }

    onClosePricingModal() {
        const { history } = this.props;
        this.setState({ showPricingModal: false });
        history.push('/listings');
    }

    onCloseCreateModal() {
        const { history } = this.props;
        this.setState({ showCreateModal: false });
        history.push('/listings');
    }

    onCloseClaimingModal() {
        const { history } = this.props;
        this.setState({ showClaimingModal: false });
        history.push('/listings');
    }

    onSelectPackage(planID) {
        this.setState({
            selectedPlan: planID,
            showCreateModal: true,
        });

        this.onClosePricingModal();
    }
}

export const listingColumns = [
    {
        label: '',
        propertyPath: 'Logo',
        renderCell: (row, value) => {
            const imageSrc = !!row.Logo.ImageThumbnailURL
                ? row.Logo.ImageThumbnailURL
                : row.MainImage.ImageThumbnailURL;
            return <img src={imageSrc} />
        }
    },
    {
        label: 'Name, Category & Address',
        renderCell: row => {
            return (
                <Fragment>
                    <Typography variant="h4">
                        {row.Title}
                    </Typography>
                    <Typography variant="body1">
                        {row.MainCategory.Title}
                    </Typography>
                    <Typography variant="body1">
                        {joinDefined([row.City, row.State], ' - ')}
                    </Typography>
                </Fragment>
            );
        }
    },
    {
        label: 'Contact Details',
        hiddenSm: true,
        renderCell: row => {
            return (
                <Fragment>
                    <ul className='table-list table-list--icons'>
                        <li>
                            <Icon>room</Icon> {
                                row.StreetAddress ? joinDefined([
                                    row.StreetAddress,
                                    row.City,
                                    row.Suburb,
                                    row.State,
                                    row.PostCode,
                                ], ', ') : ' N/A '
                            }
                        </li>
                        <li>
                            <Icon>call</Icon> {
                                row.Phone ? <a href={'tel:'+row.Phone} target="_blank">{row.Phone}</a> : ' N/A '
                            }
                        </li>
                        <li>
                            <Icon>mail</Icon> {
                                row.Email ? <a href={'mailto:'+row.Email} target="_blank">{row.Email}</a> : ' N/A '
                            }
                        </li>
                        <li>
                            <Icon>http</Icon> {
                                row.Website
                                    ? <a href={row.Website} target="_blank">{row.Website.replace('http://', '').replace('https://', '')}</a>
                                    : ' N/A '
                            }
                        </li>
                    </ul>
                </Fragment>
            );
        }
    },
    {
        label: 'Plan',
        hiddenSm: true,
        renderCell: row => {
            const plan = row.ListingSubscription.Plan;

            // todo - icon
            return (
                <Fragment>
                    <h4>{plan.Name}</h4>
                    {plan.IsFree ? (
                        'Free'
                        // todo - upgrade button
                    ) : (
                        `${plan.Price}/${plan.Period}`
                    )}
                </Fragment>
            );
        }
    },
    {
        label: 'Status',
        hiddenSm: true,
        renderCell: row => {
            // icon

            return (
                <span>{row.Status}</span>
            );
        }
    },
    {
        label: '',
        renderCell: (row, value) => {
            return (
                <Fragment>
                    <Button href={'/listing/edit/' + row.ID} variant="outlined">
                        Manage Listing
                    </Button>
                </Fragment>
            );
        }
    }
];

export const listingFragment = gql`
    fragment ListingFragment on Listing {
        ID
        Status
        Title
        StreetAddress
        City
        Suburb
        State
        PostCode
        Phone
        Email
        Website
        MainCategory {
            ID
            Title
        }
        ListingSubscription {
            ID
            Plan { 
                ID
                Name
                IsFree
                Price
                PeriodType
            }
        }
        Logo {
            ... on SilverStripeImage {
                ID
                Name
                Size
                AbsoluteLink
                ImageThumbnailURL
            }
        }
        MainImage {
            ... on SilverStripeImage {
                ID
                Name
                Size
                AbsoluteLink
                ImageThumbnailURL
            }
        }
    }
`;

const listingsQuery = gql`
    query ReadListings(
        $limit: Int
        $offset: Int
        $memberID: ID
    ){
        readListings(
            limit: $limit
            offset: $offset 
            memberID: $memberID 
        ){
            edges {
                node {
                    ID
                }
            }
        }
    }
`;

const claimListingFields = `
    ID
    Title
    MainCategory {
        ID
        Title
    }
    Logo {
        ... on SilverStripeImage {
            ID
            Name
            Size
            AbsoluteLink
            ImageThumbnailURL
        }
    }
    MainImage {
        ... on SilverStripeImage {
            ID
            Name
            Size
            AbsoluteLink
            ImageThumbnailURL
        }
    }
`;
const claimedRequestsQuery = gql`
    query ReadClaimListingRequests($memberID: ID) {
        readClaimListingRequests(memberID: $memberID) {
            ID
            Status
            Listing {
                ... on Listing {
                    ${claimListingFields}
                }
                ... on Accommodation {
                    ${claimListingFields}
                }
                ... on Activity {
                    ${claimListingFields}
                }
            }
        }
    }
`;

export const hasListingsClaimsQuery = gql`
    query ReadMemberHasListings($memberID: ID!) {
        readMemberHasListings(MemberID: $memberID) {
            HasListings
            HasClaims
        }
    }
`;

export default withTranslation()(Listings);
