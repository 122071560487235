import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '../../../components/layout/Grid';
import TextField from '../../../components/form/TextField';
import CategoryTreeField from "../../../components/form/CategoryTreeField";
import { withTranslation, Trans } from 'react-i18next'
import {getUser} from "../../../utils/sessions";
import Modal from '../../../components/modal/Modal';
import CremationOrderAutoComplete from "../../../components/form/ListingAutoComplete";
import UploadField from "../../../components/form/UploadField";

class ClaimListingModal extends Component {
    state = {
        proofDoc: null,
        listing: null,
        categoriesLoaded: false,
    };

    render() {
        const { open, onClose } = this.props;
        return (
            <Modal open={open} title="Claim a Listing">
                <Grid container spacing={3}>
                    <Grid item>
                        <Mutation mutation={listingClaimMutation} onCompleted={this.onCompleted}>
                            {this.renderForm}
                        </Mutation>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    renderForm = (mutate, results = {}) => {
        const { loading } = results;
        const { planID, onClose } = this.props;

        const { proofDoc, listing } = this.state;
        const error = '';

        const onSubmit = e => {
            if (e) e.preventDefault();
            if (loading) return;

            mutate({
                variables: {
                    listingID: listing.ID,
                    proofDocID: proofDoc.id,
                    memberID: getUser().ID
                }
            });
        };

        return (
            <form
                className="sign-in login-popup"
                onSubmit={onSubmit}
                ref="form"
            >
                <div className='field-holder'>
                    <FormLabel>
                        <Trans i18nKey="myListings.claimListing.title">
                            Listing
                        </Trans>
                    </FormLabel>
                    <CremationOrderAutoComplete
                        selectProps={{ multiple: false }}
                        placeholder="Search for your listing..."
                        onSelect={(_, listing) => this.handleSelectListing(listing)}
                        variant="outlined"
                        value={listing ? listing.Title : ''}
                    />
                </div>
                <div className='field-holder'>
                    <FormLabel>
                        <Trans i18nKey="myListings.claimListing.proof">
                            Proof Document
                        </Trans>
                    </FormLabel>
                    <UploadField
                        folderPath='ListingImages/Portal/ClaimDocs/'
                        onComplete={({ uploadFile }) => this.setState({ proofDoc: uploadFile})}
                    />
                    {!!proofDoc && (
                        <div className="uploaded-image-details">
                            <div className="uploaded-image-detail">
                                <img src={proofDoc.url} width={100} height={100} />
                                <Button
                                    title="Remove image"
                                    variant="link-red"
                                    onClick={(e) => this.setState({ proofDoc: null })}
                                >
                                    Remove
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <div className='action-buttons'>
                    <div className='button-flex'>
                        <Button variant="outlined" onClick={e => onClose()}>
                            <Trans i18nKey="buttonCancel">
                                Cancel
                            </Trans>
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            className="button-base primary"
                            disabled={!listing || !proofDoc || loading}
                        >
                            <Trans i18nKey="myListings.claimListing.button">
                                Claim
                            </Trans>
                        </Button>
                    </div>
                </div>
            </form>
        );
    };

    handleSelectListing(listing) {
        if (listing) {
            this.setState({ listing: listing });
        }
    }

    onCompleted = data => {
        const claim = (data && data.createClaimListingRequest);
        if (!claim) return;

        const { onClaimed, onClose } = this.props;
        if (onClaimed) {
            onClaimed();
        }

        this.setState({
            listing: null,
            proofDoc: null,
        });

        if (onClose) {
            onClose();
        }
    };

}

export const listingClaimMutation = gql`
    mutation ClaimListing($listingID: ID!, $memberID: ID!, $proofDocID: ID!) {
        createClaimListingRequest(ListingID: $listingID, MemberID: $memberID, ProofDocID: $proofDocID) {
            ID
        }
    }
`;

export default withTranslation()(ClaimListingModal);