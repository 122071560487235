import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from "graphql-tag";
import { Icon, Button, Typography } from '@material-ui/core';
import { withTranslation, Trans } from 'react-i18next'
import Grid from '../../components/layout/Grid';
import DataTable from '../../components/dataTable/DataTable'
import {isFunction, joinDefined} from "../../utils/strings";
import {getUser} from "../../utils/sessions";
import ViewHeader from '../../components/header/ViewHeader';
import Spinner from '../../components/loading/Spinner';
import {hasListingsClaimsQuery, listingColumns, listingFragment} from "../Listings/Listings";

class Dashboard extends Component {

    render() {
        const { history } = this.props;

        return (
            <Fragment>

                {this.renderListings()}

                {this.renderClaimedRequests()}

            </Fragment>
        );
    }

    renderListings() {
        const { history } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item>
                    <Typography variant="h1">
                        <Trans i18nKey="dashboard.title">
                            Welcome!
                        </Trans>
                    </Typography>
                    <Typography variant="body1">
                        <p>
                            <Trans i18nKey="dashboard.description.item1">
                                You've just discovered the best way to market your tourism business online to a global audience.
                                We offer a range of services and solutions that are growing constantly,
                                focused on helping tourism businesses get found online, gain more bookings and ultimately make more money.
                            </Trans>
                        </p>
                        <h3><Trans i18nKey="dashboard.description.item2">Right now you can:</Trans></h3>
                        <ol>
                            <li>
                                <Trans i18nKey="dashboard.description.item2part1">
                                    List your business on our travel website www.discover.lk for free.
                                </Trans>&nbsp;

                                <Button variant="outlined" onClick={e => history.push('/listings/new')}>
                                    <Trans i18nKey="dashboard.description.button1">
                                        Start Now
                                    </Trans>
                                </Button>
                            </li>
                            <li>
                                <Trans i18nKey="dashboard.description.item2part2">
                                    Develop a simple, good value and highly effective website for your business.
                                </Trans>&nbsp;

                                <a href="mailto:hello@discover.lk">
                                    <Trans i18nKey="dashboard.description.button2">
                                        Let's talk
                                    </Trans>
                                </a>
                            </li>
                        </ol>
                        <h3><Trans i18nKey="dashboard.description.item3">Coming soon:</Trans></h3>
                        <ol>
                            <li><Trans i18nKey="dashboard.description.item3part1">Learn how to market your business online with our learning labs and online resources</Trans></li>
                            <li><Trans i18nKey="dashboard.description.item3part2">Setup a simple online booking system for your business allowing you to take bookings online, including payments.</Trans></li>
                        </ol>

                    </Typography>
                </Grid>

                <Grid item>
                    <ViewHeader
                        variant="record"
                        titleTag="h3"
                        title={<Trans i18nKey="myListings.listView.listingsTitle">Your Current Listings</Trans>}
                    >
                        <Button variant="outlined" onClick={e => history.push('/listings/new')}>
                            <Trans i18nKey="myListings.listView.addTitle">
                                Add a new listing
                            </Trans>
                        </Button>
                    </ViewHeader>

                    <Grid contianer className='table-container'>
                        <Grid item className="listings">
                            <DataTable
                                queryName="readListings"
                                fragment={listingFragment}
                                columns={listingColumns}
                                variables={{ limit: 2, memberID: getUser().ID }}
                                brandProperty="ID"
                                searchVariable="contains"
                                tableTitle="Listings"
                                versioningMode="DRAFT"
                                hidePagination
                                noResultsMessage={<Trans i18nKey="myListings.noResultsMessage">You have no listings added yet.</Trans>}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderClaimedRequests() {
        const { history } = this.props;
        return (
            <Query
                fetchPolicy="network-only"
                query={hasListingsClaimsQuery}
                variables={{ memberID: getUser().ID}}
            >
                {results => {
                    const { loading, data, refetch } = results;

                    if (loading) return null;

                    const requests = data.readMemberHasListings;

                    return !requests.HasClaims ? (
                        <Grid container spacing={3}>
                            <Grid item>
                                <Typography variant="h3">
                                    <Trans i18nKey="myListings.claimListing.addTitle">
                                        Claim an existing listing
                                    </Trans>
                                </Typography>
                                <Typography variant="body1">
                                    <Trans i18nKey="myListings.claimListing.addContent1">
                                        If your business is already listed on
                                    </Trans>&nbsp;
                                    <strong><a href="https://www.discover.lk" target="_blank">Discover.lk</a></strong>,&nbsp;
                                    <Trans i18nKey="myListings.claimListing.addContent2">
                                        you can claim a listing by clicking the button below.
                                    </Trans>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="outlined"
                                    onClick={e => history.push('/listings/claim')}
                                    style={{ marginRight: 10 }}
                                >
                                    <Trans i18nKey="myListings.claimListing.claim">
                                        Claim a listing
                                    </Trans>
                                </Button>
                            </Grid>
                        </Grid>
                    ) : null
                }}
            </Query>
        );
    }

}

export default withTranslation()(Dashboard);
