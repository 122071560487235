/* eslint-disable no-console */
import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { withStyles } from '@material-ui/core/styles';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo/index';
import TickIcon from './icon/TickIcon';
import SaveIcon from './icon/SaveIcon';
import Spinner from './loading/Spinner';
import {
    deleteTypeName,
    diff,
    getProperty,
    isNullOrUndefined,
    resetAllProperties,
    setProperty
} from '../utils/objects';
import Button from './form/Button';
import { gqlArr as gql } from '../utils/fragment';
import { removeById } from '../utils/arrays';
import { enableValidation, validationUtility } from '../utils/validation';
import AlertBar from './form/AlertBar';
import Inline from './layout/Inline';

/*
 * DataForm is used for saving DataObjects.
 */

class DataForm extends React.Component {
    constructor(props) {
        super(props);
        // Set state function is passed down to children as a callback
        // so we need to bind the context of `this`
        this.setState = this.setState.bind(this);
    }

    state = {
        validation: validationUtility.createState()
    };

    componentWillMount() {
        this.updateFormState(this.props);
        const { history } = this.props;
        this.unblock = history.block(this.onHistoryBlock);
    }

    componentWillReceiveProps(nextProps) {
        this.updateFormState(nextProps);
    }

    componentWillUnmount() {
        this.unblock();
    }

    render() {
        const { children, error, loading, data } = this.props;
        const { validation } = this.state;

        if (error) {
            console.error(error);
            return <AlertBar variant="error">Error loading data. {error}</AlertBar>;
        }

        if (!loading && !data) {
            return <AlertBar variant="error">Failed load data</AlertBar>;
        }

        const form = {
            state: this.getTabState(),
            setState: this.setNestedState,
            getState: this.getNestedState,
            setField: this.setNestedState,
            getField: this.getNestedState,
            checkValidity: (tab) => this.handleValidate(tab),
            setExtraField: (field, value) => this.setExtraField(field, value),
            getMutation: () => this.getMutation(),
            onSaveCompleted: this.onSaveCompleted,
            handleMutateError: this.handleMutateError,
            addToNestedArray: this.addToNestedArray,
            removeFromNestedArray: this.removeFromNestedArray,
            loading,
            validation: validation,
            getValidation: this.getValidation,
            invalidate: () => this.invalidate(),
            save: () => this.executeManualSave(),
            isDirty: this.verifyIsModified()
        };

        const buttons = this.renderAllButtons();

        return <Fragment>{children(form, buttons)}</Fragment>;
    }

    renderSave() {
        const { loading } = this.props;

        const hasData = Object.keys(this.getOriginalState()).length > 0;
        if (!hasData && loading) return;

        this.verifyIsModified();

        const disabled = loading || this.savePending || !hasData || !this.isModified;

        return [
            <Button variant="confirmation" onClick={e => this.onSave(e)} disabled={disabled}>
                {this.renderButtonState(disabled)}
            </Button>,
            !disabled
        ];
    }

    renderAllButtons() {
        const { additionalActions, readOnly } = this.props;

        const saveButton = readOnly ? [null, true] : this.renderSave();

        if (additionalActions && additionalActions.length) {
            return (
                <Inline nowrap>
                    {additionalActions.map((action, i) => {
                        return (
                            <Button
                                key={'action' + i}
                                variant={action.variant}
                                onClick={() => action.onClick()}
                                disabled={(!action.skipSave && !!saveButton[1]) || !!action.disabled}
                            >
                                {action.label}
                            </Button>
                        );
                    })}

                    {saveButton[0]}
                </Inline>
            );
        } else {
            return saveButton[0];
        }
    }

    renderButtonState(disabled) {
        const { buttonLabels } = this.props;
        const { isSaving, isUnsaved, isNew, isSaved } = buttonLabels || {};
        const icon = disabled ? <TickIcon /> : <SaveIcon />;

        if (this.savePending) {
            return (
                <Fragment>
                    <Spinner size="xs" />
                    <span>{isSaving || 'Saving'}</span>
                </Fragment>
            );
        }

        if (Object.keys(this.getOriginalState()).length === 0) {
            return (
                <Fragment>
                    {icon}
                    <span>{isNew || 'Save New'}</span>
                </Fragment>
            );
        }

        if (this.isModified) {
            return (
                <Fragment>
                    {icon}
                    <span>{isUnsaved || 'Save changes and Proceed'}</span>
                </Fragment>
            );
        }

        return (
            <Fragment>
                {icon}
                <span>{isSaved || 'Saved'}</span>
            </Fragment>
        );
    }

    handleValidate(tab) {
        const bool = validationUtility.validate(this, tab.validation);
        if (!bool) {
            this.forceUpdate();
        }
        return bool;
    }

    verifyIsModified() {
        // Recalculate the modified state
        this.input = diff(this.getTabState(), this.getOriginalState(), false);

        // console.log(Object.keys(this.input));
        this.isModified = !!Object.keys(this.input).length;
        return this.isModified;
    }

    original = {};
    loaded = {};
    isModified = false;

    getValidation = (fieldName, revalidate = false) => {
        const { validation } = this.state;
        return validationUtility.getValidationResult(fieldName, validation, revalidate);
    };

    //gets the state relative to the current tab
    getTabState(tabId) {
        const { tab } = this.props;
        const state = this.state;
        return state[tabId || tab.id];
    }

    //sets the state specific to the current tab
    setTabState(state, tabId, callback) {
        const { tab } = this.props;
        if (callback) {
            this.setState({ [tabId || tab.id]: state }, callback());
        } else {
            this.setState({ [tabId || tab.id]: state });
        }
    }

    //gets the original state relative to the current tab
    getOriginalState(tabId) {
        const { tab } = this.props;
        return this.original[tabId || tab.id];
    }

    //sets the original state specific to the current tab
    setOriginalState(state, tabId = null) {
        const { tab, context } = this.props;
        this.original[tabId || tab.id] = state;
        this.verifyIsModified();
        context.forceUpdate();
    }

    getNestedState = field => {
        return getProperty(this.getTabState(), field);
    };

    setNestedState = (newState, includeOriginal = false, callback = null) => {
        const state = this.getTabState();
        for (let fieldName in newState) {
            setProperty(state, fieldName, newState[fieldName]);
        }
        this.setTabState(state, null, callback);
        if (includeOriginal) this.setOriginalState(cloneDeep(state));
    };

    addToNestedArray = (item, arrayName, context) => {
        const array = this.getNestedState(arrayName);
        array.push(item);
        this.setNestedState({ [arrayName]: array }, true);
    };

    removeFromNestedArray = (itemId, arrayName, context) => {
        const array = this.getNestedState(arrayName);
        removeById(array, itemId);
        this.setNestedState({ [arrayName]: array }, true);
    };

    updateFormState(props) {
        const { tab, tabs, loading, data, onLoad, createNew } = props;
        const { id } = this.props;
        const newState = {};
        if (props.id !== id) {
            this.original = {};
            this.loaded = {};
            Object.keys(this.getTabState()).forEach(key => (newState[key] = undefined));
            Object.keys(tabs).forEach(key => (this.original[tabs[key].id] = {}));
        }
        if (!loading && data) {
            if (tab) {
                if (!this.loaded[tab.id]) {
                    this.loaded[tab.id] = true;
                    const original = deleteTypeName(cloneDeep(data));
                    if (onLoad) onLoad(original);
                    if (tab.onLoad) tab.onLoad(original);

                    Object.assign(newState, cloneDeep(original));

                    if (createNew) {
                        //clear all preset properties from original (if we have default parameters)
                        resetAllProperties(original);
                    }

                    this.setOriginalState(original, tab.id);
                    /*
						console.log(
							`${props.name} assigned orig for ${tab.id}`,
							{ ...newState },
							{ ...original }
						);
		   			*/
                }
            } else {
                throw new Error('no tab/view supplied to dataform');
            }
        }

        if (!this.tabLookup) {
            this.tabLookup = tabs.reduce((acc, t) => {
                acc[t.id] = t;
                return acc;
            }, {});
        }

        if (Object.keys(newState).length) {
            this.setTabState(newState, tab.id);
        }
    }

    /**
     * Copies over all ID fields from the src object to the tgt object.
     */
    mergeIds(src, tgt) {
        if (!src || !tgt) return;
        if (Array.isArray(src)) {
            src.forEach((_, i) => this.mergeIds(src[i], tgt[i]));
        } else if (typeof src === 'object') {
            //if (src.hasOwnProperty('ID')) tgt.ID = src.ID;
            if (src.hasOwnProperty('ID') && !tgt.hasOwnProperty('ID')) tgt.ID = src.ID;
            if (tgt.ID === 0 || tgt.ID === '0') tgt.ID = null; // remove ID = 0 so that new items can be saved
            Object.keys(src).forEach(k => this.mergeIds(src[k], tgt[k]));
        }
    }

    setExtraField(field, value) {
        this.input[field] = value;
    }

    getMutation() {
        let input = cloneDeep(this.input);

        const { fragments, tabFragmentLookup, name, id, idType, customFragment, extraFields } = this.props;

        if (extraFields) {
            extraFields.map(field => {
                input[field] = this.getNestedState(field);
            });
        }

        // Recurse all nested objects of the original and make sure IDs are included in the input.
        this.mergeIds(this.getOriginalState(), input);

        const { tab } = this.props;
        if (isNullOrUndefined(tab)) throw new Error('No tab specified');

        // Go through all modified keys to determine which tabs have been modified
        const modifiedTabs = {};
        const requiredFields = ['ID'];
        Object.keys(input).forEach(key => {
            if (requiredFields.includes(key)) return;
            const tabKeys = tabFragmentLookup[key] || [];
            tabKeys.forEach(k => {
                if (this.tabLookup[k]) modifiedTabs[k] = true;
                else
                    console.error(
                        'cannot find tab ',
                        k,
                        ' in tab collection ',
                        this.tabLookup,
                        '. Check the id property of the tab'
                    );
            });
        });
        this.modifiedTabKeys = Object.keys(modifiedTabs);


        this.modifiedTabKeys.forEach(k => {
            const { formatSaveData } = this.tabLookup[k];
            if (formatSaveData) formatSaveData(input, this.getTabState());
        });

        // Build up all the list of required fragment parts and field names to query in the response
        const fieldNames = [];

        const fragmentParts = [];

        const related = [];

        //console.log('your input:', input, this.getOriginalState());
        Object.keys(input).forEach(key => {
            if (requiredFields.includes(key)) return;
            if (typeof input[key] === 'object' && input[key] !== null && input[key] !== undefined) {
                // For object fields we find the matching fragment that selects that object
                let fragment = customFragment || fragments[key];

                if (!fragment) {
                    //this is likely a nested fragment that needs to be resolved via the lookup
                    const tabNames = tabFragmentLookup[key];
                    if (isNullOrUndefined(tabNames)) {
                        console.log('undefined tabNames', key, tabFragmentLookup);
                        throw new Error('data form cannot resolve tab name from property in fragment lookup');
                    }

                    let identifiedFragment = null;
                    let nestedPropertyPaths = null;

                    if (tabNames.length === 1 && this.tabLookup[tabNames[0]]) {
                        identifiedFragment = this.tabLookup[tabNames[0]].fragment;
                    } else if (Array.isArray(input[key])) {
                        for (let x = 0; x < tabNames.length && identifiedFragment === null; x++) {
                            const tabName = tabNames[x];

                            if (fragments[tabName]) identifiedFragment = fragments[tabName];
                            else if (this.tabLookup[tabName]) identifiedFragment = this.tabLookup[tabName].fragment;
                        }
                    } else {
                        //this input contains modified nested properties. find the appropriate fragment
                        nestedPropertyPaths = Object.keys(input[key]).map(x => `${key}_${x}`);

                        for (let x = 0; x < tabNames.length && identifiedFragment === null; x++) {
                            const tabName = tabNames[x];
                            for (let y = 0; y < nestedPropertyPaths.length && identifiedFragment === null; y++) {
                                const propertyPath = nestedPropertyPaths[y];
                                const mappedTabName = tabFragmentLookup[propertyPath];
                                if (mappedTabName) {
                                    identifiedFragment = fragments[tabName];
                                }
                            }
                        }

                        //this is a nested object. But it's possibly a fragment. so just grab the first
                        if (isNullOrUndefined(identifiedFragment) && typeof input[key] === 'object') {
                            identifiedFragment = this.tabLookup[tabNames[0]].fragment;
                        }
                    }

                    //the data form failed to identify the tab, and subsequently the fragment that this nested property exists on
                    if (isNullOrUndefined(identifiedFragment)) {
                        console.log(`data form '${name}' cannot resolve tab from property '${key}' in fragment lookup`);
                        console.log('tabNames', tabNames);
                        console.log('this.tabLookup', this.tabLookup);
                        console.log('this.tabLookup[tabNames]', this.tabLookup[tabNames]);
                        console.log('tabFragmentLookup', tabFragmentLookup);
                        console.log('input', input);
                        console.log('fragments', fragments);
                        console.log('fragmentParts', fragmentParts);
                        console.log('nestedPropertyPaths', nestedPropertyPaths);
                        console.log('this.tabLookup[tabNames[0]]', this.tabLookup[tabNames[0]]);

                        throw new Error('data form cannot resolve tab from property in fragment lookup');
                    }

                    fragment = identifiedFragment;
                }

                fragmentParts.push(fragment);
            } else if (key.length > 2 && key.endsWith('ID')) {
                //this is related. like ContactID. so we nest
                const relatedFragment = key.substring(0, key.length - 2);
                if (customFragment) {
                    related.push(customFragment);
                } else if (fragments[relatedFragment]) {
                    related.push(fragments[relatedFragment]);
                } else {
                    const tabNames = tabFragmentLookup[relatedFragment];
                    const tabLookup = !!tabNames && this.tabLookup[tabNames[0]];
                    if (!!tabLookup) related.push(tabLookup.fragment);
                }
                //note: added this if condition to block from undefined nested fragments
                //typically this works if your nested bits are defined separately, but sometimes
                //they're defined inside the single fragment, which means they dont need to be spread
            } else {
                fieldNames.push(key);
            }
        });

        /*
		console.log('----------------');
		console.log('fragments', fragments);
		console.log('fieldNames', fieldNames);
		console.log('fragmentParts', fragmentParts);
		console.log('related', related);
		console.log('input', {...input});
		*/

        let { mutationName, mutationInputType, createNew, versioningMode } = this.props;

        if (createNew && !input.ID && idType && id) {
            input[idType] = id;
        }

        return {
            mutation: gql`
				mutation ${mutationName}($input: ${mutationInputType}!) {
					${mutationName}(Input: $input) {
                        ${requiredFields.join('\n')}
                        ${fieldNames.join('\n')}
                        ${fragmentParts.map(f => '...' + f.definitions[0].name.value).join('\n')}
                        ${related.map(f => '...' + f.definitions[0].name.value).join('\n')}
                    }
				}
				${fragmentParts}
				${related}
			`,

            variables: {
                input
            }
        };
    }

    onHistoryBlock = location => {
        const { history } = this.props;
        const currentParts = history.location.pathname.split('/').filter(x => x.length > 0);

        const currentId = currentParts.length > 0 ? currentParts[currentParts.length - 1] : null;

        const targetParts = location.pathname.split('/').filter(x => x.length > 0);

        const targetId = targetParts.length > 0 ? targetParts[targetParts.length - 1] : null;

        // We don't block the navigation if we're navigate to another form page for the
        // same item, or if we haven't made any changes to the object
        if (this.isModified && currentId !== targetId) {
            return 'You have unsaved changes, are you sure you want to leave this page?';
        }
    };

    onSave = e => {
        e.preventDefault();
        const { tabs, tab, validation } = this.props;
        if (enableValidation && tab.validation && !validationUtility.validate(this, tab.validation)) {
            this.forceUpdate();
            return;
        } else if (enableValidation && validation && !validationUtility.validate(this, validation)) {
            this.forceUpdate();
            return;
        }
        if (1 === 2 && enableValidation && Array.isArray(tabs) && !validationUtility.validateTabs(this, tabs)) {
            // this is broken because dataform is hacked to only operate on one tab at a time.
            this.forceUpdate();
            return;
        }

        const { onBeforeSave } = this.props;
        if (!onBeforeSave || onBeforeSave(this)) {
            this.executeSave();
        }
    };

    executeSave() {
        const { customClient, client } = this.props;
        (customClient || client)
            .mutate(this.getMutation())
            .then(this.onSaveCompleted)
            .catch(this.handleMutateError);
        this.savePending = true;
        this.forceUpdate();
    }

    /**
     * if you need to call save from the form object, this will verify whether it can actually execute
     */
    executeManualSave() {
        const { loading } = this.props;
        const hasData = Object.keys(this.getOriginalState()).length > 0;
        if (!hasData && loading) return;

        this.verifyIsModified();

        if (loading || this.savePending || !hasData || !this.isModified) return;

        this.executeSave();
    }

    onSaveCompleted = ({ data }) => {
        const updated = Object.keys(data)[0];
        this.isModified = false;
        this.savePending = false;

        const { onSaved, onLoad, makeTabChange, tabs, selectedIndex } = this.props;

        if (!data[updated]) {
            console.log('warning! save function returned a null object!', data);
        }
        let original = deleteTypeName(cloneDeep(data[updated]));
        if (onLoad) onLoad(original);
        if (this.modifiedTabKeys && this.modifiedTabKeys.length) {
            this.modifiedTabKeys.forEach(tabKey => {
                const onLoadTab = this.tabLookup[tabKey].onLoad;
                if (onLoadTab) onLoadTab(original);
            });
        }

        const oldOriginal = this.getOriginalState();
        const newOriginal = { ...oldOriginal, ...original };

        this.setOriginalState(newOriginal);
        this.setTabState(cloneDeep(newOriginal));

        if (onSaved) {
            // console.log('sending to save func', { ...newOriginal });
            onSaved({ ...newOriginal });
        }

        this.invalidate();
        this.forceUpdate();

        if (makeTabChange && tabs.length > (selectedIndex + 1)) {
            makeTabChange(selectedIndex + 1);
        }
    };

    handleMutateError = e => {
        this.savePending = false;
        const { name, onGqlError } = this.props;
        if (onGqlError) onGqlError(e, `Mutation failed for '${name}'`);
        else console.error('gql error', e);
        this.forceUpdate();
    };

    invalidate() {
        const { tab, parentDataForm, context, name } = this.props;

        //console.log(`invalidated ${tab.id}`);
        this.loaded[tab.id] = false; //unload the tab so it can get loaded again before next render

        if (parentDataForm) {
            parentDataForm.invalidate(); //traverse to the parent and update
        } else {
            if (!context) console.error(`${name} needs context={this}, in order to trigger a re-render`);
            context.forceUpdate(); //force the context to rerender so the tab gets loaded
        }
    }
}

export default compose(
    withApollo,
    withRouter,
    withStyles({})
)(DataForm);
