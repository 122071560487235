import React, {createRef, Component, Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import FormLabel from '@material-ui/core/FormLabel';
import { Editor } from '@tinymce/tinymce-react';
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import Listing from "../../../fragments/Listing";
import Accordion from "../../../components/accordion/Accordion";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ListingTab from "./ListingTab";

L.Icon.Default.imagePath='/images/';

class ContactDetails extends ListingTab {

    constructor(props) {
        super(props);
        this.state = {
            defaultCenter: [6.776273, 79.913700],
            zoom: 10,
            currentPos: null,
        };
        this.handleMapClick = this.handleMapClick.bind(this);
    }

    componentDidMount() {
        const { form } = this.props;
        const location = form.getField('Location');

        if (location) {
            this.setState({ currentPos: this.parseLocation(location) });
        }
    }

    render() {
        const { form } = this.props;
        const objectType = form.getField('ClassNameShort');
        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        <Accordion
                            title="Location"
                            titleI18nKey="myListings.editView.tabLocation"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderLocationDetails()}
                        </Accordion>

                        {(objectType === 'Accommodation') && (
                            <Accordion
                                title="Proximity"
                                titleI18nKey="myListings.editView.tabProximity"
                                className='form-panel'
                                defaultExpanded
                            >
                                {this.renderProximityDetails()}
                            </Accordion>
                        )}

                        <Accordion
                            title="Map"
                            titleI18nKey="myListings.editView.tabMap"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderLocationMap()}
                        </Accordion>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderLocationDetails() {
        const { form } = this.props;
        const streetAddress = <Trans i18nKey="myListings.editView.address">Street Address</Trans>;
        const city = <Trans i18nKey="myListings.editView.city">City</Trans>;
        const suburb = <Trans i18nKey="myListings.editView.suburb">Suburb</Trans>;
        const state = <Trans i18nKey="myListings.editView.state">State</Trans>;
        const postCode = <Trans i18nKey="myListings.editView.postCode">Post Code</Trans>;
        const country = <Trans i18nKey="myListings.editView.country">Country</Trans>;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="StreetAddress"
                        variant="outlined"
                        label={streetAddress}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="City"
                        variant="outlined"
                        label={city}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Suburb"
                        variant="outlined"
                        label={suburb}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="State"
                        variant="outlined"
                        label={state}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="PostCode"
                        variant="outlined"
                        label={postCode}
                        fullWidth
                    />
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Country"
                        variant="outlined"
                        label={country}
                        fullWidth
                    />
                </Grid>
            </Grid>
        )
    }

    renderProximityDetails() {
        const { form } = this.props;
        const proximityToTown = <Trans i18nKey="myListings.editView.proximityToTown">Proximity To Town</Trans>;
        const proximityToAirport = <Trans i18nKey="myListings.editView.proximityToAirport">Proximity To Airport</Trans>;
        const proximityToRailway = <Trans i18nKey="myListings.editView.proximityToRailway">Proximity To Railway</Trans>;
        const proximityToBus = <Trans i18nKey="myListings.editView.proximityToBus">Proximity To Bus</Trans>;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="ProximityToTown"
                        type="number"
                        variant="outlined"
                        label={proximityToTown}
                        fullWidth
                    />
                    <div className="field-help-text">
                        <Trans i18nKey="myListings.editView.proximityHelp">
                            Value should be in km. eg: 2.5
                        </Trans>
                    </div>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="ProximityToAirport"
                        type="number"
                        variant="outlined"
                        label={proximityToAirport}
                        fullWidth
                    />
                    <div className="field-help-text">
                        <Trans i18nKey="myListings.editView.proximityHelp">
                            Value should be in km. eg: 2.5
                        </Trans>
                    </div>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="ProximityToRailway"
                        type="number"
                        variant="outlined"
                        label={proximityToRailway}
                        fullWidth
                    />
                    <div className="field-help-text">
                        <Trans i18nKey="myListings.editView.proximityHelp">
                            Value should be in km. eg: 2.5
                        </Trans>
                    </div>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="ProximityToBus"
                        type="number"
                        variant="outlined"
                        label={proximityToBus}
                        fullWidth
                    />
                    <div className="field-help-text">
                        <Trans i18nKey="myListings.editView.proximityHelp">
                            Value should be in km. eg: 2.5
                        </Trans>
                    </div>
                </Grid>
            </Grid>
        )
    }

    renderLocationMap() {
        const { form } = this.props;
        const { currentPos, defaultCenter } = this.state;

        return (
            <Map
                scrollWheelZoom={false}
                center={currentPos ? currentPos : defaultCenter}
                zoom={this.state.zoom}
                onClick={this.handleMapClick}
            >
                <TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'/>

                { this.state.currentPos && <Marker position={this.state.currentPos} draggable={true} /> }
            </Map>
        );
    }

    handleMapClick(e) {
        const { form } = this.props;
        this.setState({ currentPos: e.latlng });

        form.setField({ Location: e.latlng });
    }

    parseLocation = (wkt) => {
        const parts = wkt.match(/\b(point|linestring|polygon|multipolygon)\s*\((.*)\)/i);

        const coordinates = parts[2]
            .replace(/([\d\.-]+)\s+([\d\.-]+)/g, function(p,c1,c2) {
                return '{"lat": ' + c2 + ', "lng": ' + c1 + '}';
            }).replace(/([\(\)])/g, function(p,c) {
                return c === '(' ? '' : '';
            });

        return JSON.parse(coordinates);
    };
}

export default {
    id: 'contact',
    tabURL: '/listing/edit/:id/location',
    label: 'Location & Contacts',
    component: withTranslation()(ContactDetails),
    fragment: Listing,
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

        // handle location save
        const location = state.Location;
        if (location && typeof location === 'object' && location.lat && location.lng) {
            saveData.Location = `POINT(${location.lng} ${location.lat})`;
        }

    }
};