import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import { compose } from 'react-apollo/index';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import DataForm from './DataForm';
import Spinner from './loading/Spinner';
import Tabs from './layout/Tabs';
import { buildTabFragmentLookup, createFragments } from '../utils/fragment';
import { isNullOrUndefined } from '../utils/objects';
import Separator from './layout/Separator';
import {applyUrlParams} from "../utils/strings";
import {getClient} from "../utils/apollo";
import {enableValidation, validationUtility} from "../utils/validation";

/**
 * Wraps a data form for viewing. Requires a few parameters to work properly
 *
 * @param tabs an array of tabs. The tab has the following structure:
            {
                id: "SubPanelBracketsTab1",                 unique id that maps back to this tab
                label: "Brackets",                          the label of this tab
                component: SubPanelBracketsView,            the view, contains all the form fields
                fragment: BracketsTabBracketsFragment,      fragment to load when shown
                validation: BracketsTabValidationObject,	validation fields & functions for this tab only
                onLoad: data => {},                         manipulate data before loading the view
                formatSaveData: (saveData, state) => {}     manipulate the data before saving
            }
 * @param createNew whether a new object should be created when this view is shown
 * @param createNewFunc creates an empty version of the object
 * @param objectType type of the object in the database
 * @param parentObjectType type of the parent/connected object in the database
 * @param parentId id of the parent to connect to when saving
 * @param name of this view
 * @param context of the parent view
 * @param form parent form
 * @param itemId id of the object to load
 * @param onCreateNew executes after a new object has been created/saved to the database
 * @param onSaved executes after an object has been saved to the database (returns the new object)
 * @param viewMode use the import DataFormViewMode to select single, tabbed or stepped view
 * @param viewLoaded function that is called once a view loads for the first time. NOTE: There's a bit of kink here. It doesn't trigger on the first view (because the viewLoaded is called in componentDidUpdate). Gotta fix this eventually
 * @param validation global validation objects that apply to all tabs (unless tab has its own validation)
 * @param readOnly stops save button being rendered
 */

const DataFormViewMode = {
    single: 0,
    tabbed: 1
};

class DataFormView extends Component {
    static getDerivedStateFromProps({ itemId, createNew, tabs }, oldState) {
        let newState = { ...oldState };

        //build the tab lookup if it hasn't been built yet
        if (oldState.tabFragmentLookup === null) {
            newState.tabFragmentLookup = buildTabFragmentLookup(tabs);
            newState.fragments = createFragments(tabs);
            newState.loaded = tabs.map(() => false);

            newState.viewMode = tabs.length > 1 ? DataFormViewMode.tabbed : DataFormViewMode.single;
        }

        if (createNew || isNullOrUndefined(itemId)) {
            if (oldState.itemId === null) {
                //if its brand new, set it to createNew = true
                newState.createNew = true;
            } else {
                //this was a createNew, but it's been saved and now has a itemId
                newState.createNew = false;
            }
        } else if (itemId !== oldState.itemId) {
            //this is an existing thing
            newState.itemId = itemId;
        }
        return newState;
    }

    state = {
        selectedIndex: 0,
        itemId: null,
        createNew: false,
        tabFragmentLookup: null,
        fragments: null,
        viewMode: DataFormViewMode.single,
        loaded: [],
        tabLoading: false,
    };

    componentDidMount() {
        const { tabs, context } = this.props;
        const { location, history } = context.props;

        let selectedIndex = tabs.findIndex((tab, index) => {
            return location.pathname === this.getTabPath(index);
        });

        const defaultTabPath = this.getTabPath(0);
        if (defaultTabPath) {
            if (selectedIndex === -1) {
                history.replace(defaultTabPath);
                selectedIndex = 0;
            }

            this.setState({ selectedIndex: selectedIndex })
        }
    }

    componentDidUpdate() {
        const { loaded, selectedIndex } = this.state;

        if (!loaded[selectedIndex]) {
            loaded[selectedIndex] = true;
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ loaded });

            const { viewLoaded } = this.props;
            if (viewLoaded) {
                viewLoaded(selectedIndex);
            }
        }
    }

    render() {
        const { selectedIndex, itemId, createNew } = this.state;
        const { tabs, createNewFunc, objectType, customFragment, client, versioningMode } = this.props;
        const tab = tabs[selectedIndex];

        if (createNew) {
            return this.renderForm({
                loading: false,
                data: { [`readOne${objectType}`]: createNewFunc() }
            });
        }

        // versioningMode options => ARCHIVE | LATEST | DRAFT | LIVE
        const versionString = versioningMode ? `, Versioning: { Mode: ${versioningMode} }` : '';

        const fragment = customFragment ? customFragment : tab.fragment;
        const query = gql`
			${fragment}
			query readOne${objectType}($id: ID!) {
				readOne${objectType}(ID: $id${versionString}) {
					ID
					...${fragment.definitions[0].name.value}
				}
			}
		`;

        return (
            <Query
                query={query}
                variables={{
                    id: itemId
                }}
                client={client}>
                {results => this.renderForm(results)}
            </Query>
        );
    }

    renderForm({ loading, error, data }) {
        const {
            parentId,
            form,
            tabs,
            name,
            parentObjectType,
            objectType,
            context,
            onSaved,
            onBeforeSave,
            buttonLabels,
            readOnly,
            validation,
            additionalActions,
            children,
            client,
            customFragment,
            mutationName,
            mutationInputType,
            extraFields,
        } = this.props;

        const { selectedIndex, itemId, tabFragmentLookup, fragments, viewMode, createNew } = this.state;
        const tab = tabs[selectedIndex];

        if (loading) return this.renderLoading();
        if (error) return this.renderError(error);

        //get the save functions
        let saveFunc = createNew ? s => this.onCreateNew(s) : s => onSaved && onSaved(s);

        //get the appropriate function to render the view
        let renderViewFunc = null;
        switch (viewMode) {
            case DataFormViewMode.single:
                renderViewFunc = this.renderSingleView;
                break;
            case DataFormViewMode.tabbed:
                renderViewFunc = this.renderTabbedView;
                break;
            default:
                throw new Error('unknown view mode', name);
        }

        return (
            <DataForm
                name={name}
                id={createNew ? parentId : itemId}
                idType={createNew ? `${parentObjectType}ID` : 'ID'}
                tab={tab}
                loading={loading}
                error={error}
                onSaved={saveFunc}
                onBeforeSave={onBeforeSave}
                data={data && data[`readOne${objectType}`]}
                mutationName={mutationName ? mutationName : createNew ? `create${objectType}` : `update${objectType}`}
                mutationInputType={mutationInputType
                    ? mutationInputType
                    : createNew ? `${objectType}CreateInputType` : `${objectType}UpdateInputType`
                }
                createNew={createNew}
                context={context}
                fragments={fragments}
                tabs={tabs}
                tabFragmentLookup={tabFragmentLookup}
                parentDataForm={form}
                buttonLabels={buttonLabels}
                selectedIndex={selectedIndex}
                readOnly={readOnly}
                additionalActions={additionalActions}
                validation={validation}
                customClient={client}
                customFragment={customFragment}
                extraFields={extraFields}
                makeTabChange={this.makeTabChange.bind(this)}
            >
                {(form, saveButton) => (
                    <form>
                        {renderViewFunc(form, saveButton)}
                        {children}
                    </form>
                )}
            </DataForm>
        );
    }

    renderSingleView = (form, saveButton) => {
        const { classes, tabs, createNew, itemId, parentId, tabProps, context } = this.props;
        const { selectedIndex, tabLoading } = this.state;
        const tab = tabs[selectedIndex];

        return (
            <div className={tabLoading && 'tab-loading'}>
                { tabLoading && <Spinner/> }
                <tab.component
                    form={form}
                    createNew={createNew}
                    itemId={itemId}
                    parentId={parentId}
                    saveButton={saveButton}
                    tabProps={tabProps}
                    context={context}
                />

                {!tab.handleButtons && (
                    <div className={`${classes.footerButtons} form-footer`}>
                        {this.renderButtons(saveButton, tab.buttons)}
                    </div>
                )}
            </div>
        );
    };

    renderTabbedView = (form, saveButton) => {
        const { classes, tabs, className } = this.props;
        const { selectedIndex } = this.state;

        return (
            <Fragment>
                <div className={className}>
                    <div className={classes.root}>
                        <Tabs
                            value={selectedIndex}
                            onChange={(e, index) => this.onChange(e, index, form)}
                            variant="scrollable"
                            scrollButtons="off">
                            {tabs.map((tab, index) => (
                                <Tab key={tab.id} selected={index === selectedIndex} label={tab.label} />
                            ))}
                        </Tabs>
                        <Separator />
                        <div className={classes.content}>{this.renderSingleView(form, saveButton)}</div>
                    </div>
                </div>
            </Fragment>
        );
    };

    renderButtons(saveButton, otherButtons) {
        if (Array.isArray(otherButtons))
            return (
                <Fragment>
                    {otherButtons}
                    {saveButton}
                </Fragment>
            );
        if (otherButtons instanceof Function) return otherButtons(saveButton);
        return saveButton;
    }

    renderError(error) {
        const { classes, className } = this.props;
        return (
            <span className={className}>
                <div className={classes.graphQLFeedbackFull}>An error occurred: {error}</div>
            </span>
        );
    }

    renderLoading() {
        const { classes, className } = this.props;
        return (
            <span className={className}>
                <div className={classes.root}>
                    <div className={classes.loading}>
                        <Spinner />
                    </div>
                </div>
            </span>
        );
    }

    getTabPath(index) {
        const { tabs, context } = this.props;
        const tab = tabs[index];
        if (tab.tabURL) {
            const { match } = context.props;
            return applyUrlParams(tab.tabURL, match.params);
        }
        return null;
    }

    async onChange(event, selectedIndex, form) {
        if (form.isDirty) {
            const { tabs } = this.props;
            const { selectedIndex } = this.state;
            const tab = tabs[selectedIndex];

            if (enableValidation && tab.validation && !form.checkValidity(tab)) {
                return;
            }

            const client = this.props.client || getClient();
            this.setState({ tabLoading: true });
            const response = await client.mutate(form.getMutation());
            form.onSaveCompleted(response);
            this.setState({ tabLoading: false });
            // form.save();
        }

        this.makeTabChange(selectedIndex);
    };

    makeTabChange(index) {
        const { context } = this.props;
        const { history } = context.props;

        const tabURL = this.getTabPath(index);
        history.push(tabURL);

        this.setState({ selectedIndex: index });
    }

    onCreateNew(newObject) {
        this.setState({ itemId: newObject.ID, createNew: false });

        const { onCreateNew } = this.props;
        if (onCreateNew) {
            onCreateNew(newObject);
        }
    }
}

const styles = () => ({
    footerButtons: {
        paddingTop: '5px',
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

DataFormView.propTypes = {
    objectType: PropTypes.string.isRequired
};

export default compose(withStyles(styles))(DataFormView);
