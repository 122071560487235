import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { isNullOrUndefined } from 'util';
import { InputAdornment } from '@material-ui/core';
import { getValidationDecorations } from '../../utils/validation';
import { stringIsNullOrEmpty } from '../../utils/strings';
import { getElementValueWrapper, onChangeWrapper } from '../../utils/objects';
import Spinner from '../loading/Spinner';
import Button from './Button';
import { dateToString } from '../../utils/date';
import ReadonlyField from './ReadonlyField';

class FormTextField extends Component {
    state = {
        showButton: true
    };

    render() {
        let {
            classes,
            name,
            id,
            form,
            value,
            rows,
            rowsMax,
            title,
            InputProps = {},
            InputLabelProps,
            fullWidth,
            label,
            type,
            notFuture,
            validationResult,
            error,
            capitalize,
            onChange,
            onBlur,
            readOnly,
            icon,
            loading,
            emptyValue,
            button,
            ...other
        } = this.props;

        if (notFuture) {
            InputProps.max = moment().format('YYYY-MM-DD');
        }

        const baseClass = other.multiline ? 'input--multiline' : 'input';
        const decorations = getValidationDecorations(this.props);

        let formValue = getElementValueWrapper(this);

        if (type === 'date') {
            InputLabelProps = { shrink: true };

            if (readOnly && formValue) {
                let dateValue = moment(formValue);

                if (dateValue.isValid()) {
                    formValue = dateToString(dateValue);
                }
            }
        }

        if (type === 'time') {
            InputLabelProps = { shrink: true };
            formValue = ('' + formValue).replace(/(:\d\d):00/, '$1');

            if (readOnly && formValue) {
                let timeValue = moment.utc(formValue, 'HH:mm');

                if (timeValue.isValid()) {
                    formValue = timeValue.format('hh:mm a');
                }
            }
        }

        if (decorations.inError === true) {
            InputLabelProps ? (InputLabelProps.shrink = true) : (InputLabelProps = { shrink: true });
        }

        if (readOnly) {
            return <ReadonlyField label={label}>{formValue}</ReadonlyField>;
        }

        if (!isNullOrUndefined(button)) {
            const { showButton } = this.state;
            if (!!showButton && stringIsNullOrEmpty(formValue)) {
                return this.renderButton();
            }
        }

        let startAdornment;
        if (!isNullOrUndefined(icon)) {
            startAdornment = <InputAdornment position="start">{loading ? <Spinner size="xs" /> : icon}</InputAdornment>;
        }

        return (
            <span className="textfield input">
                <TextField
                    FormHelperTextProps={{
                        error: decorations.inError === true,
                        classes: {
                            error: `validation-label ${decorations.cssClass}`
                        }
                    }}
                    readOnly={readOnly}
                    helperText={decorations.errorMsg}
                    title={title}
                    id={id || (name ? `text-${name}` : undefined)}
                    name={name}
                    rows={other.multiline ? (rows ? rows : 4) : null}
                    rowsMax={other.multiline ? (rowsMax ? rowsMax : 9) : null}
                    value={formValue}
                    onChange={e => onChangeWrapper(e, this)}
                    onBlur={onBlur ? e => onBlur(e) : () => false}
                    fullWidth={fullWidth}
                    label={
                        !stringIsNullOrEmpty(label) && (
                            <Fragment>
                                <span className="label-text">{label}</span>
                            </Fragment>
                        )
                    }
                    type={type}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            root: `${baseClass} ${decorations.cssClass}`,
                            focused: 'input--focused'
                        },
                        startAdornment,
                        ...InputProps
                    }}
                    InputLabelProps={{
                        className: 'label',
                        classes: {
                            shrink: `label--shrink ${decorations.cssClass}`
                        },
                        ...InputLabelProps
                    }}
                    {...other}
                />
            </span>
        );
    }

    renderButton() {
        const { button } = this.props;
        return <Button {...button} onClick={() => this.setState({ showButton: false })} />;
    }
}

FormTextField.defaultProps = {
    fullWidth: false,
    emptyValue: ''
};

export default withStyles({})(FormTextField);
