import React, { Component, Fragment, Suspense } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { compose } from 'react-apollo/index';
import './css/app.scss';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Main from './views/Main';
import { isSignedIn } from './utils/sessions';
import { stringIsNullOrEmpty } from './utils/strings';
import Unsplash, {toJson} from 'unsplash-js';
import { unsplashAccessKey } from './environment';
import theme from './components/Theme';


const unsplash = new Unsplash({ accessKey: unsplashAccessKey });

class App extends Component {
    state = {
        signedIn: false,
        drawerOpen: false,
        drawerMinimized: false,
        background: 'https://images.unsplash.com/photo-1561150018-8bb356679537'
    };


    componentWillMount() {
        this.updateState(this.props);

        unsplash.photos.getRandomPhoto({
                query: "sri lanka"
            })
            .then(toJson)
            .then(json => {
                if (json.urls.regular) {
                    this.setState({
                        background: json.urls.regular
                    });
                }
            });
    }

    componentWillReceiveProps(nextProps) {
        this.updateState(nextProps);
    }

    render() {
        const { signedIn } = this.state;
        const { location, history } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <div
                    className={signedIn ? 'app app-main' : 'welcome-screen'}
                    style={{backgroundImage: !signedIn && this.state.background ? 'url(' + this.state.background + ')' : false}}
                >
                    <Header signedIn={signedIn} history={history} location={location} />

                    <div className={!signedIn? 'welcome-main': 'app-window'}>
                        {signedIn
                            ? (
                                <div className='grid-fixer'>
                                    <Main signedIn={signedIn} />
                                </div>
                            ) : (
                                <Main signedIn={signedIn} />
                            )
                        }

                    </div>
                    <Footer signedIn={signedIn} />
                </div>
            </ThemeProvider>
        );
    }

    updateState() {
        const newState = {};
        const newSignedIn = isSignedIn();
        const { signedIn } = this.state;
        if (signedIn !== newSignedIn) {
            newState.signedIn = newSignedIn;
        }

        if (Object.keys(newState).length) {
            this.setState(newState);
        }
    }

    onToggleDrawerOpen = () => {
        const { drawerOpen } = this.state;
        this.setState({ drawerOpen: !drawerOpen });
    };

    onToggleDrawerMinimized = () => {
        const { drawerMinimized } = this.state;
        this.setState({ drawerMinimized: !drawerMinimized });
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        const { setSnackbarMessage } = this.props;
        setSnackbarMessage('');
    };

    renderSnackbar = ({ snackbarMessage }) => {
        const open = !stringIsNullOrEmpty(snackbarMessage);
        return (
            <Snackbar
                message={<span id="message-id">{snackbarMessage}</span>}
                open={open}
                autoHideDuration={3000}
                onClose={this.handleCloseSnackbar}
                className="snackbar"
            />
        );
    };
}

// prettier-ignore
export default compose(
    withRouter,
    withStyles({})
)(App);
