import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { withTranslation, Trans } from 'react-i18next'
import { Icon, Button } from '@material-ui/core';
import Paper from '../../components/layout/Paper';
import Grid from '../../components/layout/Grid';
import DataFormView from '../../components/DataFormView';
import BasicDetails from './Tabs/BasicDetails';
import ContactDetails from './Tabs/ContactDetails';
import HoursAndRates from './Tabs/HoursAndRates';
import Media from './Tabs/Media';
import Integrations from './Tabs/Integrations';
import Spinner from '../../components/loading/Spinner';
import ViewHeader from '../../components/header/ViewHeader';
import Accommodation from "../../fragments/Accommodation";
import Listing from "../../fragments/Listing";
import Preview from "./Tabs/Preview";
import DealsAndAlerts from "./Tabs/DealsAndAlerts";

/**
 * Tab Definition
 */
const CremationTabs = [
    BasicDetails,
    ContactDetails,
    HoursAndRates,
    Media,
    Integrations,
    DealsAndAlerts,
    Preview,
];

class ListingEdit extends Component {

    render() {
        const { match } = this.props;
        return (
            <Fragment>
                <Query query={query} variables={{ id: match.params.id }}>
                    {results => {
                        const { loading, data } = results;
                        if (loading) return <Spinner/>;

                        const listing = data && data.readOneListing;
                        if (!listing) return null;

                        return (
                            <Fragment>
                                {this.renderHeader(data && data.readOneListing)}

                                <Paper elevation={0} className='step-forms-container'>
                                    <DataFormView
                                        tabs={CremationTabs}
                                        createNewFunc={{
                                            Title: null,
                                        }}
                                        objectType={listing.ClassNameShort === 'Accommodation' ? 'Accommodation' : 'Listing'}
                                        customFragment={listing.ClassNameShort === 'Accommodation' ? Accommodation : Listing}
                                        itemId={match.params.id}
                                        name="Listing"
                                        onCreateNew={e => this.onCreateNew(e)}
                                        context={this}
                                        versioningMode="DRAFT"
                                        mutationName={listing.ClassNameShort === 'Accommodation' ? 'updatePortalAccommodation' : 'updatePortalListing'}
                                        mutationInputType="ListingInput"
                                        extraFields={['Version', 'VersionForReview']}
                                    />
                                </Paper>
                            </Fragment>
                        );

                    }}
                </Query>
            </Fragment>
        );
    }

    renderHeader = (listing) => {
        if (!listing) return null;

        const { history } = this.props;
        return (
            <Grid item>
                <ViewHeader variant="record" title={listing.MainCategory.Title} subtitle={listing.Title}>
                    <Button onClick={() => history.push('/listings')} variant="outlined">
                        <Icon>chevron_left</Icon>
                        <Trans i18nKey="backButton">
                            Back
                        </Trans>
                    </Button>
                </ViewHeader>
            </Grid>
        );
    };

    onCreateNew(listing) {
        const { history } = this.props;
        history.push(`listing/edit/${listing.ID}`);
    }
}

const query = gql`
    query readOneListing($id: ID!) {
        readOneListing(ID: $id, Versioning: { Mode: DRAFT}) {
            ID
            Title
            ClassNameShort
            MainCategory {
                Title
            }
        }
    }
`;

export default withTranslation()(ListingEdit);
