import React, { Component, Fragment } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import {clearToken} from "../../utils/sessions";
import { withTranslation, Trans } from 'react-i18next';
import LogoutIcon from '../icon/LogoutIcon';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DnsIcon from '@material-ui/icons/Dns';
import HomeIcon from '@material-ui/icons/Home';

class Header extends Component {

    state = {
        drawerNav: false
    };

    render() {
        const { signedIn, history, location } = this.props;
        let cssClass = 'header';
        if (signedIn) {
            cssClass += ' header--dashboard';
        }
        return (
            <div className={cssClass}>
                <div className="app-container">
                    <Grid
                        className="header-grid"
                        container spacing={5}
                    >
                        <Grid item xs={8} lg={3}>
                            <a href="/" className="logo">

                                <div className="title"><h2>
                                    <img src="/images/logo.svg" className="black" alt="Discover.lk" />
                                    <img src="/images/logo-black.svg" className="yellow" alt="Discover.lk" />
                                </h2></div>
                                <div className="subtitle">
                                    <p>
                                        <Trans i18nKey="title">
                                            Tourism Business Portal
                                        </Trans>
                                    </p>
                                </div>
                            </a>
                        </Grid>
                        {signedIn && (
                            <Fragment>
                                <Grid item xs={4} lg={9} container className="menu-list menu-list__primary">
                                    <Hidden lgUp={true} lgDown={false}>

                                        <Button onClick={e => {this.toggleDrawer(true)}} className="menu-list__hamburger"><MenuIcon/></Button>
                                        <Drawer anchor='left' open={this.state.drawerNav} onClose={e => {this.toggleDrawer(false)}}>
                                            <ul className="menu-list__drawer">
                                                <li className={'menu-item ' + (location.pathname === '/' ? 'active' : '')}>
                                                    <a onClick={e => this.loadPage('/')}>
                                                        <Trans i18nKey="mainMenu.home">
                                                            Home
                                                        </Trans>
                                                        <HomeIcon></HomeIcon>
                                                    </a>
                                                </li>
                                                <li className={'menu-item ' + (location.pathname.includes('/listing') ? 'active' : '')}>
                                                    <a onClick={e => this.loadPage('/listings')}>
                                                        <Trans i18nKey="mainMenu.myListings">
                                                            My Listings
                                                        </Trans>
                                                        <DnsIcon></DnsIcon>
                                                    </a>
                                                </li>
                                                {/*<li className={'menu-item ' + (location.pathname === '/manage-website' ? 'active' : '')}>*/}
                                                {/*<a onClick={e => history.push('/manage-website')}>*/}
                                                {/*<Trans i18nKey="mainMenu.websites">*/}
                                                {/*Websites*/}
                                                {/*</Trans>*/}
                                                {/*</a>*/}
                                                {/*</li>*/}
                                                {/*<li className={'menu-item ' + (location.pathname === '/booking-system' ? 'active' : '')}>*/}
                                                {/*<a onClick={e => history.push('/booking-system')}>*/}
                                                {/*<Trans i18nKey="mainMenu.bookings">*/}
                                                {/*Bookings*/}
                                                {/*</Trans>*/}
                                                {/*</a>*/}
                                                {/*</li>*/}
                                                <li className={'menu-item ' + (location.pathname === '/account' ? 'active' : '')}>
                                                    <a onClick={e => this.loadPage('/account')}>
                                                        <Trans i18nKey="mainMenu.account">
                                                            Account
                                                        </Trans>
                                                        <AccountBoxIcon></AccountBoxIcon>
                                                    </a>
                                                </li>
                                                <li className="menu-item logout">
                                                    <a onClick={e => this.onClickLogout()}>
                                                        <Trans i18nKey="mainMenu.logout">
                                                            Log out
                                                        </Trans>
                                                        <ExitToAppIcon></ExitToAppIcon>
                                                    </a>
                                                </li>
                                            </ul>
                                        </Drawer>

                                    </Hidden>
                                    <Hidden only={['xs', 'sm', 'md']} >
                                        <ul>
                                            <li className={'menu-item ' + (location.pathname === '/' ? 'active' : '')}>
                                                <a onClick={e => this.loadPage('/')}>
                                                    <Trans i18nKey="mainMenu.home">
                                                        Home
                                                    </Trans>
                                                </a>
                                            </li>
                                            <li className={'menu-item ' + (location.pathname.includes('/listing') ? 'active' : '')}>
                                                <a onClick={e => this.loadPage('/listings')}>
                                                    <Trans i18nKey="mainMenu.myListings">
                                                        My Listings
                                                    </Trans>
                                                </a>
                                            </li>
                                            {/*<li className={'menu-item ' + (location.pathname === '/manage-website' ? 'active' : '')}>*/}
                                                {/*<a onClick={e => history.push('/manage-website')}>*/}
                                                    {/*<Trans i18nKey="mainMenu.websites">*/}
                                                        {/*Websites*/}
                                                    {/*</Trans>*/}
                                                {/*</a>*/}
                                            {/*</li>*/}
                                            {/*<li className={'menu-item ' + (location.pathname === '/booking-system' ? 'active' : '')}>*/}
                                                {/*<a onClick={e => history.push('/booking-system')}>*/}
                                                    {/*<Trans i18nKey="mainMenu.bookings">*/}
                                                        {/*Bookings*/}
                                                    {/*</Trans>*/}
                                                {/*</a>*/}
                                            {/*</li>*/}
                                            <li className={'menu-item ' + (location.pathname === '/account' ? 'active' : '')}>
                                                <a onClick={e => this.loadPage('/account')}>
                                                    <Trans i18nKey="mainMenu.account">
                                                        Account
                                                    </Trans>
                                                </a>
                                            </li>
                                            <li className="menu-item logout">
                                                <a onClick={e => this.onClickLogout()}>
                                                    <Trans i18nKey="mainMenu.logout">
                                                        Log out
                                                    </Trans>
                                                    <ExitToAppIcon></ExitToAppIcon>
                                                </a>
                                            </li>
                                        </ul>
                                    </Hidden>
                                    {/* only={['lg', 'xl']} */}
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                </div>
            </div>
        );
    }

    loadPage(url) {
        const { history } = this.props;
        history.push(url);
        this.toggleDrawer(false);
    }

    onClickLogout = () => {
        const { history } = this.props;
        clearToken();
        history.push('/');
    };

    toggleDrawer(open) {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //     return;
        // }

        this.setState({ drawerNav: open });
    }
}

export default withTranslation()(Header);
