import React, { Component, Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import Accordion from "../../../components/accordion/Accordion";
import Listing from "../../../fragments/Listing";
import ListingTab from "./ListingTab";

class HoursAndRates extends ListingTab {

    state = {
        openingHours: [
            {
                Day: 'Sunday',
                From: '',
                To: '',
            },
            {
                Day: 'Monday',
                From: '',
                To: '',
            },
            {
                Day: 'Tuesday',
                From: '',
                To: '',
            },
            {
                Day: 'Wednesday',
                From: '',
                To: '',
            },
            {
                Day: 'Thursday',
                From: '',
                To: '',
            },
            {
                Day: 'Friday',
                From: '',
                To: '',
            },
            {
                Day: 'Saturday',
                From: '',
                To: '',
            },
        ]
    };

    componentDidMount() {
        const { form } = this.props;
        const { openingHours } = this.state;
        const hours = form.getField('OpeningHours') ? JSON.parse(form.getField('OpeningHours')) : null;

        if (hours) {
            openingHours.map((obj, index) => {
                this.state.openingHours[index].From = hours[[`${obj.Day}OpeningFrom`]];
                this.state.openingHours[index].To = hours[[`${obj.Day}OpeningTo`]];
            });
            this.forceUpdate();
        }
    }

    render() {
        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        <Accordion
                            title="Hours"
                            titleI18nKey="myListings.editView.tabHours"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderHours()}
                        </Accordion>

                        <Accordion
                            title="Rates"
                            titleI18nKey="myListings.editView.tabRates"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderRates()}
                        </Accordion>

                        <Accordion
                            title="Facilities"
                            titleI18nKey="myListings.editView.tabFacilities"
                            className='form-panel'
                            defaultExpanded
                        >
                            {this.renderFacilities()}
                        </Accordion>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    renderOpeningHoursDay(obj, index) {
        const { form } = this.props;
        const { openingHours } = this.state;

        const handleCopyDayBefore = () => {
            onTimeFieldChange(this.state.openingHours[index-1].From, 'From');
            onTimeFieldChange(this.state.openingHours[index-1].To, 'To');
        };

        const onTimeFieldChange = (value, name) => {
            this.state.openingHours[index][name] = value;
            this.forceUpdate();

            form.setField({ OpeningHours: this.state.openingHours });
        };

        return (
            <tr>
                <td className='opening-hours__day'>{obj.Day}</td>
                <td className='opening-hours__input'>
                    <TextField
                        variant="outlined"
                        fullWidth
                        type="time"
                        value={obj.From}
                        onChange={e => onTimeFieldChange(e.target.value, 'From')}
                    />
                </td>
                <td className='opening-hours__input'>
                    <TextField
                        variant="outlined"
                        fullWidth
                        type="time"
                        value={obj.To}
                        onChange={e => onTimeFieldChange(e.target.value, 'To')}
                    />
                </td>
                <td className='opening-hours__actions'>
                    {index > 0 && (openingHours[index-1].From || openingHours[index-1].To) && (
                        <Button variant="outlined" onClick={e => handleCopyDayBefore()}>
                            Copy day before
                        </Button>
                    )}
                </td>
            </tr>
        );
    }

    renderHours() {
        const { form } = this.props;
        const { openingHours } = this.state;
        const objectType = form.getField('ClassNameShort');
        const checkIn = <Trans i18nKey="myListings.editView.checkIn">Check In</Trans>;
        const checkOut = <Trans i18nKey="myListings.editView.checkOut">Check Out</Trans>;

        return (
            <Grid container spacing={0}>
                {(objectType === 'Activity' || objectType === 'Listing') && (
                    <Grid item md={8} className="opening-hours field-holder">
                        <FormLabel>
                            <Trans i18nKey="myListings.editView.hours">
                                Opening Hours
                            </Trans>
                        </FormLabel>

                        <table cellSpacing={0} cellPadding={0} className='opening-hours__table'>
                            <thead>
                                <tr>
                                    <th className='opening-hours__day'>&nbsp;</th>
                                    <th className='opening-hours__input'>From</th>
                                    <th className='opening-hours__input'>To</th>
                                    <th className='opening-hours__actions'>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {openingHours.map((obj, index) => this.renderOpeningHoursDay(obj, index))}
                            </tbody>
                        </table>

                    </Grid>
                )}
                {objectType === 'Accommodation' && (
                    <Fragment>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="CheckIn"
                                type="time"
                                variant="outlined"
                                label={checkIn}
                                fullWidth
                            />
                            <div className="field-help-text">
                                eg: 10:00 AM
                            </div>
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="CheckOut"
                                type="time"
                                variant="outlined"
                                label={checkOut}
                                fullWidth
                            />
                            <div className="field-help-text">
                                eg: 6:00 PM
                            </div>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        )
    }

    renderRates() {
        const { form } = this.props;
        const averageSpeed = <Trans i18nKey="myListings.editView.spend">Average Spend</Trans>;
        const minPrice = <Trans i18nKey="myListings.editView.minPrice">Min Price</Trans>;
        const maxPrice = <Trans i18nKey="myListings.editView.maxPrice">Max Price</Trans>;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="AverageSpend"
                        variant="outlined"
                        label={averageSpeed}
                        fullWidth
                        type="number"
                        InputProps={{ inputProps: { min: 0, step:".01" } }}
                    />

                    <p className="field-help-text">
                        <Trans i18nKey="myListings.editView.twitterHelp">
                            Average spend (Enter in USD). E.G 15.00
                        </Trans>
                    </p>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="MinPrice"
                        variant="outlined"
                        label={minPrice}
                        fullWidth
                        type="number"
                        InputProps={{ inputProps: { min: 0, step:".01" } }}
                    />
                    <Trans i18nKey="myListings.editView.twitterHelp">
                        Minimum price (Enter in USD). E.G 15.00
                    </Trans>
                </Grid>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="MaxPrice"
                        variant="outlined"
                        label={maxPrice}
                        fullWidth
                        type="number"
                        InputProps={{ inputProps: { min: 0, step:".01" } }}
                    />
                    <Trans i18nKey="myListings.editView.twitterHelp">
                        Maximum price (Enter in USD). E.G 15.00
                    </Trans>
                </Grid>
            </Grid>
        )
    }

    renderFacilities() {
        const { form } = this.props;
        const facilities = <Trans i18nKey="myListings.editView.facilities">Facilities</Trans>;
        const numberOfRooms = <Trans i18nKey="myListings.editView.numOfRooms">Num Of Rooms</Trans>;
        const numberOfFunctionRooms = <Trans i18nKey="myListings.editView.numOfFunctionRooms">Num Of Function Rooms</Trans>;
        const theatreCapacity = <Trans i18nKey="myListings.editView.rheatreCapacity">Theatre Capacity</Trans>;
        const banquetCapacity = <Trans i18nKey="myListings.editView.banquetCapacity">Banquet Capacity</Trans>;
        const cocktailCapacity = <Trans i18nKey="myListings.editView.cocktailCapacity">Cocktail Capacity</Trans>;
        const boardRoomCapacity = <Trans i18nKey="myListings.editView.boardRoomCapacity">Board Room Capacity</Trans>;

        return (
            <Grid container spacing={0}>
                <Grid item className="field-holder">
                    <TextField
                        form={form}
                        name="Facilities"
                        variant="outlined"
                        label={facilities}
                        fullWidth
                        multiline
                        rows={6}
                    />
                    <Trans i18nKey="myListings.editView.twitterHelp">
                        Enter a new line for each
                    </Trans>
                </Grid>
                {form.getField('ClassNameShort') === 'Accommodation' && (
                    <Fragment>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="NumOfRooms"
                                variant="outlined"
                                fullWidth
                                type="number"
                                label={numberOfRooms}
                            />
                            <Trans i18nKey="myListings.editView.twitterHelp">
                                Number only
                            </Trans>
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="NumOfFunctionRooms"
                                variant="outlined"
                                fullWidth
                                type="number"
                                label={numberOfFunctionRooms}
                            />
                            <Trans i18nKey="myListings.editView.twitterHelp">
                                Number only
                            </Trans>
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="TheatreCapacity"
                                variant="outlined"
                                fullWidth
                                type="number"
                                label={theatreCapacity}
                            />
                            <Trans i18nKey="myListings.editView.twitterHelp">
                                Number only
                            </Trans>
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="BanquetCapacity"
                                variant="outlined"
                                label={banquetCapacity}
                                fullWidth
                                type="number"
                            />
                            <Trans i18nKey="myListings.editView.twitterHelp">
                                Number only
                            </Trans>
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="CocktailCapacity"
                                variant="outlined"
                                fullWidth
                                type="number"
                                label={cocktailCapacity}
                            />
                            <Trans i18nKey="myListings.editView.twitterHelp">
                                Number only
                            </Trans>
                        </Grid>
                        <Grid item className="field-holder">
                            <TextField
                                form={form}
                                name="BoardRoomCapacity"
                                variant="outlined"
                                fullWidth
                                type="number"
                                label={boardRoomCapacity}
                            />
                            <Trans i18nKey="myListings.editView.twitterHelp">
                                Number only
                            </Trans>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        )
    }

}

export default {
    id: 'hoursAndRates',
    tabURL: '/listing/edit/:id/hours',
    label: 'Hours, Rates & Facilities',
    component: HoursAndRates,
    fragment: Listing,
    validation: {},
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {

        // handle opening hours save
        const openingHours = state.OpeningHours;
        if (openingHours && Array.isArray(openingHours)) {
            const hours = {};
            openingHours.map(obj => {
                hours[[`${obj.Day}OpeningFrom`]] = obj.From;
                hours[[`${obj.Day}OpeningTo`]] = obj.To;
            });
            saveData.OpeningHours = JSON.stringify(hours);
        }
    }
};