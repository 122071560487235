import gql from "graphql-tag";
import ListingSubscriptionPlan from "./ListingSubscriptionPlan";
import {ListingFields} from "./Listing";

export default gql`
	fragment Accommodation on Accommodation {
		${ListingFields}
        
        CheckIn
        CheckOut
        NumOfRooms
        NumOfFunctionRooms
        TheatreCapacity
        BanquetCapacity
        CocktailCapacity
        BoardRoomCapacity
	}
	${ListingSubscriptionPlan}
`;
