import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import cloneDeep from 'lodash.clonedeep';
import Button from '@material-ui/core/Button';
import Grid from '../../components/layout/Grid';
import Spinner from '../../components/loading/Spinner';
import ListingSubscriptionPlan from "../../fragments/ListingSubscriptionPlan";
import { Trans } from 'react-i18next'
import history from "../../utils/history";

class PricingPackages extends Component {

    render() {
        return (
            <Query query={subscriptionPlansQuery} variables={{ enabled: true }}>
                {results => this.renderContent(results)}
            </Query>
        )
    }

    renderContent = (results) => {
        const { loading, error, data } = results;
        const { onSelectPackage } = this.props;

        if (error) {
            return 'Error!';
        }

        if (loading) {
            return <div>
                <Spinner />
            </div>;
        }

        if (!data) {
            return '';
        }

        const plans = customisedPlans(data.readListingSubscriptionPlans);
        const features = {
            NumberOfCategories: {
                'Label': <Trans i18nKey="myListings.pricingPlan.categories">Number of Categories</Trans>,
                'Description': <Trans i18nKey="myListings.pricingPlan.categoriesDescription">Your listing can appear on multiple categories.</Trans>
            },
            NumberOfImages: {
                Label: <Trans i18nKey="myListings.pricingPlan.images">Number of images</Trans>
            },
            MobilePhone: {
                Label: <Trans i18nKey="myListings.pricingPlan.mobile">Add your mobile</Trans>
            },
            VideoEmbed: {
                Label: <Trans i18nKey="myListings.pricingPlan.video">Include Video</Trans>
            },
            MapAndDirection: {
                Label: <Trans i18nKey="myListings.pricingPlan.maps">Enable maps</Trans>
            },
            TripadvisorEmbed: {
                Label: <Trans i18nKey="myListings.pricingPlan.tripadvisor">Include Tripadvisor Ratings</Trans>
            }
        };

        return (
            <Grid container alignItems="flex-end" className='packages'>
                <table cellPadding={0} cellSpacing={0}>
                    <tbody>
                        <tr>
                            <td className='package__feature package__title-cell'>&nbsp;</td>
                            {plans.map((tier) => (
                                <td key={'package' + tier.ID} className='package__name package__plan-cell'>
                                    <h6>{tier.Name}</h6>
                                    {tier.Description && <p>{tier.Description}</p>}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td className='package__title-cell'>
                                <p><span className='strong'>
                                    <Trans i18nKey="myListings.pricingPlan.price">Price</Trans>
                                </span></p>
                            </td>
                            {plans.map((tier) => (
                                <td key={'package-price' + tier.ID} className='package__price package__plan-cell'>
                                    {
                                        tier.IsFree ?
                                            <Fragment>
                                                <span className='price'>
                                                    <Trans i18nKey="myListings.pricingPlan.free">FREE</Trans>
                                                </span>
                                            </Fragment>
                                            : <Fragment>
                                                <span className='currency'>LKR</span>
                                                <span className='price'>{(new Intl.NumberFormat().format(tier.Price))}</span>
                                                <span className='duration'>{tier.PeriodType.toLowerCase()}</span>
                                            </Fragment>
                                    }
                                </td>
                            ))}
                        </tr>
                        {Object.keys(features).map((key) => (
                            <tr key={'package-wrapper' + key}>
                                <td className='package__option-name package__title-cell'>
                                    <p>
                                        <span className='strong'>{features[key].Label}</span>
                                        {features[key].Description && <span>{features[key].Description}</span>}
                                    </p>
                                </td>
                                {plans.map((tier) => (
                                    <td key={'package-availability' + tier.ID} className='package__option package__plan-cell'>
                                        {typeof tier[key] === "boolean"
                                            ? (
                                                tier[key]
                                                    ? <Trans i18nKey="myListings.pricingPlan.yes">Yes</Trans>
                                                    : <Trans i18nKey="myListings.pricingPlan.no">No</Trans>
                                            ) : tier[key]
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <td className='package__title-cell'>&nbsp;</td>
                            {plans.map((tier) => (
                                <td key={'package-button' + tier.ID} className='package__name package__plan-cell'>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        disabled={tier.ComingSoon}
                                        onClick={e => onSelectPackage(tier.ID)}
                                    >
                                        {tier.ComingSoon ? (
                                            <Trans i18nKey="myListings.pricingPlan.comingSoon">
                                                Coming Soon!
                                            </Trans>
                                        ) : (
                                            <Trans i18nKey="myListings.pricingPlan.getStarted">
                                                Get Started
                                            </Trans>
                                        )}
                                    </Button>
                                </td>
                            ))}
                        </tr>
                    </tbody>

                </table>

            </Grid>
        );
    }
}

const customisedPlans = (plans) => {
    const updated = [];
    plans.map(e => {
        const plan = cloneDeep(e);
        plan.Features = [];

        const features = plan.Features;

        const imageCount = Number(e.NumberOfImages);
        features.push(
            imageCount > 1
                ? 'Logo and up to ' + e.NumberOfImages + ' photos'
                : 'Logo and ' + (imageCount === 1 ? 'one' : 'no') + ' photo'
        );
        const catCount = Number(e.NumberOfCategories);
        features.push(
            catCount > 1
                ? 'Display in up to ' + e.NumberOfImages + ' categories'
                : 'Display in ' + (imageCount === 1 ? 'one' : 'no') + ' category'
        );
        features.push(
            e.BookingLink
                ? 'Website link and booking link'
                : 'Website link but no booking link'
        );
        features.push(
            e.MapAndDirection
                ? 'Address, Google map and directions'
                : 'Address but no location map or direction'
        );
        features.push(
            e.Featured
                ? 'Priority display in search results'
                : 'Standard search results'
        );
        features.push(
            e.MobilePhone
                ? 'Display mobile number'
                : 'No mobile number diplayed'
        );
        features.push(
            e.VideoEmbed
                ? 'Video embed (youtube), '
                : 'No video embed, ' +
            e.TripadvisorEmbed
                ? 'display Tripadvisor embed'
                : 'no Tripadvisor embed'
        );

        updated.push(plan);
    });
    return updated;
};

export const subscriptionPlansQuery = gql`
    ${ListingSubscriptionPlan}
    
    query($enabled: Boolean) {
        readListingSubscriptionPlans(enabled: $enabled) {
            ...ListingSubscriptionPlan
        }
    }
`;

export default PricingPackages;
