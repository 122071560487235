import gql from "graphql-tag";

export default gql`
	fragment ListingSubscriptionPlan on ListingSubscriptionPlan {
        ID
        Name
        Description
        IsFree
        ComingSoon
        Price
        PeriodType
        Featured
        NumberOfImages
        NumberOfCategories
        BookingLink
        VideoEmbed
        MobilePhone
        MapAndDirection
        TripadvisorEmbed
	}
`;

