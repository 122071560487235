import React, { Component, Fragment } from 'react';
import Grid from '../../components/layout/Grid';

class Website extends Component {
    render() {
        return (
            <Grid container spacing={3}>
                <Grid item>
                    <h1>This is Website screen</h1>
                </Grid>
            </Grid>
        );
    }
}

export default Website;
